export const environment = {
  production: false,
  baseURL: 'https://intebix.kz/',
  auURL: 'https://biteeu.com.au/',

  // host: 'http://bit-back.ml:3001/'
  // host: 'http://95.211.94.106:3001/'    // use it for .ml  = dev  // OLD
  // host: 'http://62.212.83.6:3001/'      // use it for .ml  = dev
  // host: 'https://api.biteeu.com/'       // use it for .COM = prod
  // host: 'https://api.biteeu.com.au/'    // use it for .com.AU = AU-prod
};
