export class OrderStopLimitStatusClass {
  orderId: number;
  direction: string;
  pair: string;
  isInitiatedComparison: boolean;
  isComparedPreviousLastPrice: boolean;
  isComparedCurrentLastPrice: boolean;
  previousLastPrice: number;
  currensLastPrice: number;
  triggerPrice: number;
  operateWhen: string;

  constructor(orderId: number,
              direction: string,
              pair: string,
              isInitiatedComparison: boolean,
              isComparedPreviousLastPrice: boolean,
              isComparedCurrentLastPrice: boolean,
              previousLastPrice: number,
              currensLastPrice: number,
              triggerPrice: number,
              operateWhen: string
  ) {
    this.orderId = orderId;
    this.direction = direction;
    this.pair = pair;
    this.isInitiatedComparison = isInitiatedComparison;
    this.isComparedPreviousLastPrice = isComparedPreviousLastPrice;
    this.isComparedCurrentLastPrice = isComparedCurrentLastPrice;
    this.previousLastPrice = previousLastPrice;
    this.currensLastPrice = currensLastPrice;
    this.triggerPrice = triggerPrice;
    this.operateWhen = operateWhen;
  }
}
