import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-eb-app',
  templateUrl: './eb-app.component.html',
  styleUrls: ['./eb-app.component.scss']
})
export class EbAppComponent {

  constructor(public dialogRef: MatDialogRef<EbAppComponent>) { }

  close(): void {
    this.dialogRef.close();
  }
}
