import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'volumeSort'
})
export class VolumeSortPipe implements PipeTransform {

  transform(value: any, direction: any): any {
    if (direction)
      return value.sort((a, b) => {
        const keyA = Object.keys(a).filter(v => v !== 'name')[0];
        const keyB = Object.keys(b).filter(v => v !== 'name')[0];
        if (direction === 'up')
          return a[keyA].BaseVolume - b[keyB].BaseVolume;
        else
          return b[keyB].BaseVolume - a[keyA].BaseVolume;
      });
    return value;
  }

}
