//some mocks
export const balance = {
  availableBCXG: 0,
  availableOZ: 0,
  goldValue: 0
};

export const paymentOptions = [
  {value: 'Pay with BCXG from my account'},
  {value: 'Pay with other crypto from my account'},
  {value: 'Pay with a credit card'},
  {value: 'Pay with PayPal'},
  {value: 'Wire transfer'}
];

export const goldBars = [
  {
    goldSize: '10g gold bar 0.321507 OZ',
    goldGram: 10,
    quantity: 0,
    hidden: true,
    image: '../../../../assets/img/10g-slitok.png',
    title: 'Heraeus Scottsdale Gold Certi-LOCK Bar',
    text: '10g Heraeus Scottsdale Gold Certi-LOCK Bar. There are gold bars, and then there are Scottsdale Gold bars. The world famous ' +
      'lion hallmark is minted in 9999 fine gold, and co-branded with Swiss partner Heraeus makes it LBMA certified. And that\'s just ' +
      'the start. Each bar is sealed in a Certi-Lock assay card and assigned a unique set of authenticity credentials designed to give ' +
      'you more confidence when you sell, in any market. 10 Gram of .9999 Fine Gold LBMA & ISO 9001-2015.'
  },
  {
    goldSize: '50g gold bar 1.60754 OZ',
    goldGram: 50,
    quantity: 0,
    hidden: true,
    image: '../../../../assets/img/50grr.png',
    title: 'Valcambi Gold Bar',
    text: 'Valcambi innovative 50g gold investment bar, which comprises 50 detachable 1g bars. The Valcambi CombiBarTM is the best ' +
      'ever produced multifunctional bar in the world. It is technically designed to serve as an investment bar. Valcambi CombiBarTM ' +
      'benefits from Valcambi\'s outstanding craftsmanship, Hallmark, Assay Mark and assured quality. ',
    list: [
      '1. Max Diameter - 52.5mm (L) x 74mm (W) x 0.85mm (H)',
      '2. Fineness - 999.9'
    ]
  },
  {
    goldSize: '100g gold bar 3.21507 OZ',
    goldGram: 100,
    quantity: 0,
    hidden: true,
    image: '../../../../assets/img/100g.png',
    title: 'Pamp Suisse Minted Fortuna Gold Bar ',
    text: 'This beautiful pure gold bar features the classic \'Lady Fortuna\' design by private ' +
      'Swiss mint PAMP (Produits Artistiques de Métaux Précieux), sealed in a standard credit card sized tamper-proof security card.',
    list: [
      '1. Max Diameter - 47 x 27 mm',
      '2. Max Thickness - 4.3 mm',
      '3. Fineness - 99.99%'
    ]
  },
  {
    goldSize: '1000g gold bar 32.1507 OZ',
    goldGram: 1000,
    quantity: 0,
    hidden: true,
    image: '../../../../assets/img/1kg.png',
    title: 'Heraeus Feingold',
    text: 'The Heraeus gold cast bars carry a serial number and quality certificate which are sealed in plastic. Heraeus, ' +
      'the technology group headquartered in Hanau, Germany, is a leading international family- owned company formed in 1851. ' +
      'With a Code of Conduct which among other things defines binding rules and guidelines for the acceptance of precious metals, ' +
      'the company has committed itself to high standards. Only materials, which fulfill these standards, are used for the ' +
      'production of Heraus bars.',
    list: [
      '1. Max Diameter 116.5 mm x 51 mm',
      '2. Max Thickness 9.5 mm',
      '3. Fineness - 999.9'
    ]
  },
];
