import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SvgService} from '../../../services/svg.service';
import {DataService} from '../../../services/data.service';
import {AuthService} from '../../../services/auth.service';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import { ThemesModel } from '../../../models/themes.model';
import {TradesMarket} from '../../../models/trades.model';
import {GeoState} from '../../../store/geo.state';
import {fromMobx} from '../../../store/rx-from-mobx';
import {ProfileState} from '../../../store/profile.state';

@Component({
  selector: 'app-trades',
  templateUrl: './trades.component.html',
  styleUrls: ['./trades.component.scss']
})
export class TradesComponent implements OnInit, OnDestroy {
  @Input() public isMinimized: boolean;
  @Output() public minimized = new EventEmitter<any>();
  public isLightTheme = false;
  public marketHistory: Array<TradesMarket>;
  public isVisibleActualPair = false;
  public isGeoBlockedCountry = false;
  public ThemesModel = ThemesModel;
  destroySubject$: Subject<void> = new Subject();

  constructor(public svgService: SvgService,
              public dataService: DataService,
              public profileState: ProfileState,
              public geoState: GeoState,
              public authService: AuthService) {
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }

  ngOnInit() {
    this.initialDataLoading();

    this.dataService.getMarketPairNewEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        this.isVisibleActualPair = true;
        this.initialDataLoading();
        setTimeout(() => this.isVisibleActualPair = false, 2000);
      });

    this.dataService.joinRoom(); //   trades
    if (this.authService.isLoggedIn) {
      this.profileState.loadUserProfileIfEmpty();
      fromMobx(() => this.profileState.storagedUserId)
        .pipe(takeUntil(this.destroySubject$))
        .subscribe((userId) => this.dataService.joinRoomUserId('' + userId));
    }

    this.checkGeoForBlackListedCountry();
  }

  public initialDataLoading(): void {
    const self = this;

    this.dataService.getMarketHistory()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(res => {
        // const time = (new Date()).getHours() + ':' + (new Date()).getMinutes() + ':' + (new Date()).getSeconds();
        // console.log('---getMarketHistory ' + time);
        self.marketHistory = res;

        self.dataService.SubscribeMarketHistory()
          .pipe(takeUntil(this.destroySubject$))
          .subscribe((data: Array<any>) => {
          // console.log('...new trades:', data);
          if (data) {
            this.marketHistory = [...data, ...this.marketHistory];
          }
        });
      }, error1 => {
        console.log(error1);
      });
  }

  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  public blockUserActivity() {
    // this.router.navigate(['/login']);
    // this.dataService.setLoginIncomeErrorStatus(['isGeoBlockedCountry']);
    // this.dataService.passLoginIncomeErrorStatus(['isGeoBlockedCountry']);
    // this.authService.logout();
  }

  public checkGeoForBlackListedCountry() {
    this.isGeoBlockedCountry = this.geoState.isGeoCountryIsInBlackList;
    if (this.geoState.isGeoCountryIsInBlackList || this.geoState.isGeoCityIsInBlackList) {
      this.blockUserActivity();
    }
    fromMobx(() => this.geoState.isGeoCountryIsInBlackList).pipe(takeUntil(this.destroySubject$))
      .subscribe(
        (isGeoBlockedCountry) => {
          this.isGeoBlockedCountry = isGeoBlockedCountry;
          this.blockUserActivity();
        }
      );
  }


}
