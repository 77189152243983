export class FiatClass {
  CurrencyCode: string;
  Amount: number;

  constructor(CurrencyCode: string,
              Amount: number
  ) {
    this.CurrencyCode = CurrencyCode;
    this.Amount = Amount;
  }
}
