import {Component, HostListener} from '@angular/core';
import {DataService} from '../../../services/data.service';

@Component({
  selector: 'app-day-night-toggle',
  templateUrl: './day-night-toggle.component.html',
  styleUrls: ['./day-night-toggle.component.scss']
})
export class DayNightToggleComponent {
  public isLightTheme = false;
  public checked = true
  @HostListener('click', ['$event.target']) closeSelect(target) {
    if (target.name && target.name === 'checkbox1') {
      this.toggleDayNightTheme();
    }
  }

  constructor(public dataService: DataService) {
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.checked = false
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }

  public toggleDayNightTheme() {
    const newTheme = !this.isLightTheme;
    window.localStorage.setItem('isLightTheme', `${newTheme}`)
    this.dataService.passIsLightTheme(newTheme);
    this.dataService.setIsLightTheme(newTheme);
  }

}
