import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
// declare let AmCharts: any;
@Component({
  selector: 'app-chart-market-depth',
  templateUrl: './chart-market-depth.component.html',
  styleUrls: ['./chart-market-depth.component.scss'],
})
export class ChartMarketDepthComponent implements OnInit {
  // public chart: any;
  // public chartProperties_ls: any;
  constructor(private dataService: DataService) { }

  ngOnInit() {
    // this.chartProperties_ls = JSON.parse((JSON.parse(localStorage.getItem('tvxwevents.settings'))).value);
    // console.log(this.chartProperties_ls);

    // this.buildChart();
    // this.dataService.getMarketPairNewEmitter()
    //   .subscribe((response: string) => {
    //     try {
    //       this.buildChart();
    //     } catch (e) {
    //       // console.log(e);
    //     }
    //   });
  }

  // private formatNumber(val, chart, precision) {
  //   return AmCharts.formatNumber(
  //     val,
  //     {
  //       precision: precision ? precision : chart.precision,
  //       decimalSeparator: chart.decimalSeparator,
  //       thousandsSeparator: chart.thousandsSeparator
  //     }
  //   );
  // }
  // private balloon (item, graph) {
  //   // console.log('balloon 1', item);
  //   let txt;
  //   // if (graph.id === 'ask') {
  //   if (graph.id === 'sell') {
  //     txt = 'Ask: <strong>' + this.formatNumber(item.dataContext.value, graph.chart, 4) + '</strong><br />'
  //       // + 'Total volume: <strong>' + this.formatNumber(item.dataContext.askTotal, graph.chart, 4) + '</strong><br />'
  //       + 'Total volume: <strong>' + this.formatNumber(item.dataContext.sellTotal, graph.chart, 4) + '</strong><br />'
  //       + 'Volume: <strong>' + this.formatNumber(item.dataContext.sellvolume, graph.chart, 4) + '</strong>';
  //   }
  //   else {
  //     txt = 'Bid: <strong>' + this.formatNumber(item.dataContext.value, graph.chart, 4) + '</strong><br />'
  //       // + 'Total volume: <strong>' + this.formatNumber(item.dataContext.bidTotal, graph.chart, 4) + '</strong><br />'
  //       + 'Total volume: <strong>' + this.formatNumber(item.dataContext.buyTotal, graph.chart, 4) + '</strong><br />'
  //       + 'Volume: <strong>' + this.formatNumber(item.dataContext.askvolume, graph.chart, 4) + '</strong>';
  //   }
  //   // console.log('balloon 2', txt);
  //   return txt;
  // }
  //
  // private processData(data, type, desc) {
  //   let res = [];
  //   let list = data.map(item => {
  //     return {
  //       value: Number(item.Price),
  //       volume: Number(item.Size),
  //       total: Number(item.Sum),
  //     };
  //   });
  //
  //   if (desc) {
  //     for (let i = list.length - 1; i >= 0; i--) {
  //       if (i < (list.length - 1)) {
  //         list[i].Sum = list[i + 1].Sum + list[i].volume;
  //       }
  //       else {
  //         list[i].Sum = list[i].volume;
  //       }
  //       let dp = {};
  //       dp['value'] = list[i].value;
  //       dp[type + 'volume'] = list[i].volume;
  //       dp[type + 'Total'] = list[i].Sum;
  //       res.unshift(dp);
  //     }
  //   }
  //   else {
  //     for (let i = 0; i < list.length; i++) {
  //       if (i > 0) {
  //         list[i].Sum = list[i - 1].Sum + list[i].volume;
  //       }
  //       else {
  //         list[i].Sum = list[i].volume;
  //       }
  //       let dp = {};
  //       dp['value'] = list[i].value;
  //       dp[type + 'volume'] = list[i].volume;
  //       dp[type + 'Total'] = list[i].Sum;
  //       res.push(dp);
  //     }
  //   }
  //   return res;
  // }
  //
  // private buildChart() {
  //   this.chart = AmCharts.makeChart('depth-chart', {
  //     'type': 'serial',
  //     'theme': 'light',
  //     'dataProvider': [],
  //     'color': 'TRANSPARRENT',
  //     'graphs': [{
  //       'id': 'buy',
  //       // 'id': 'bid',
  //       'fillAlphas': 0.1 ,
  //       'lineAlpha': 1,
  //       'lineThickness': 1,
  //       'lineColor': '#0f0',
  //       'type': 'step',
  //       'valueField': 'buyTotal',
  //       // 'valueField': 'bidTotal',
  //       'inside': true,
  //       'balloonFunction': this.balloon.bind(this)
  //     }, {
  //       'id': 'sell',
  //       // 'id': 'ask',
  //       'fillAlphas': 0.1,
  //       'lineAlpha': 1,
  //       'lineThickness': 1,
  //       'lineColor': '#f00',
  //       'type': 'step',
  //       'valueField': 'sellTotal',
  //       // 'valueField': 'askTotal',
  //       'balloonFunction': this.balloon.bind(this)
  //     }, {
  //       'lineAlpha': 0,
  //       'fillAlphas': 0,
  //       'lineColor': '#fff',
  //       'type': 'column',
  //       'clustered': false,
  //       'valueField': 'buyvolume',
  //       // 'valueField': 'bidvolume',
  //       'inside': true,
  //       'showBalloon': false
  //     }, {
  //       'lineAlpha': 0,
  //       'fillAlphas': 0,
  //       'lineColor': '#fff',
  //       'type': 'column',
  //       'clustered': false,
  //       'valueField': 'sellvolume',
  //       // 'valueField': 'askvolume',
  //       'inside': true,
  //       'showBalloon': false
  //     }],
  //     'categoryField': 'value',
  //     'chartCursor': {},
  //     'balloon': {
  //       'textAlign': 'left'
  //     },
  //     'valueAxes': [{
  //       'title': '',
  //       'inside': false,
  //       'color': '#fff'
  //       // 'offset': -1000
  //     }],
  //     'categoryAxis': {
  //       'title': '',
  //       // 'minHorizontalGap': 0,
  //       'startOnAxis': false,
  //       'showFirstLabel': false,
  //       'showLastLabel': false,
  //       'axisAlpha': 0,
  //       'inside': false,
  //       'color': '#fff',
  //       // "offset": -1000
  //     }
  //   });
  //   this.initChart();
  // }
  //
  // private initChart() {
  //   this.dataService.getOrderBookData()
  //     .subscribe(data => {
  //       // console.log(data);
  //       this.updateChart(data);
  //     });
  // }
  //
  // private updateChart(data) {
  //   // console.log('updateChart', data);
  //   let res = [];
  //   // res = res.concat(this.processData(data.buy, 'buy', true));
  //   res = res.concat(this.processData(data.bid, 'buy', true));
  //   // res = res.concat(this.processData(data.sell, 'sell', false));
  //   res = res.concat(this.processData(data.ask, 'sell', false));
  //   // console.log('updateChart', res);
  //   if (this.chart) {
  //     this.chart.dataProvider = res;
  //     this.chart.validateNow(true, false);
  //   }
  // }

}
