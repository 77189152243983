import {Directive, HostListener, Input} from '@angular/core';
import KeyDownEvent = JQuery.KeyDownEvent;

@Directive({
  selector: 'input[appInputDenySymbols]'
})
export class InputDenySymbolsDirective {

  @Input() appInputDenySymbols: string;

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyDownEvent) {
    if (this.appInputDenySymbols.indexOf(event.key) !== -1) {
      event.preventDefault();
    }
  }

}
