import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AlertDialogComponent} from '../../../ui/alert-dialog/alert-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {DataService} from '../../../services/data.service';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {EbAppComponent} from '../../../dialogs/eb-app/eb-app.component';
import {TwofaCheckComponent} from '../../../dialogs/twofa-check/twofa-check.component';

interface DocumentFlowData {
  is2fa: boolean | null;
  isEbClient: boolean | null;
  docType: string;
  firstName: string;
  secondName: string;
  birthday: Date | null;
  phone: string;
  iin: string;
  identityNumber: string;
  personalFormFile: string;
  incomeFile: string;
}

@Component({
  selector: 'app-document-flow',
  templateUrl: './document-flow.component.html',
  styleUrls: ['./document-flow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentFlowComponent implements OnInit {

  step = 0;

  formData: DocumentFlowData = {
    is2fa: null,
    isEbClient: null,
    docType: null,
    firstName: null,
    secondName: null,
    birthday: null,
    phone: null,
    iin: null,
    identityNumber: null,
    personalFormFile: null,
    incomeFile: null
  };

  docTypes = ['WAGE', 'DIVIDENDS', 'INHERITANCE', 'CREDIT', 'PENSION', 'PREMIUM', 'BUSINESS', 'SALE_OF_PROPERTY', 'LOAN', 'DEPOSITS', 'OTHER'];

  disabledFields = [];

  waitForUpload = false;

  constructor(private _dialog: MatDialog,
              private _translateService: TranslateService,
              private _dataService: DataService,
              private _cdRef: ChangeDetectorRef,
              private _router: Router) { }

  ngOnInit(): void {
    this.loadProfileData();
  }

  loadProfileData(): void {
    this._dataService
      .getUserProfile()
      .subscribe(data => {
        if (data.first_name) {
          this.disabledFields.push('firstName');
          this.formData.firstName = data.first_name;
        }

        if (data.last_name) {
          this.disabledFields.push('secondName');
          this.formData.secondName = data.last_name;
        }

        if (data.birthday) {
          this.disabledFields.push('birthday');
          this.formData.birthday = new Date(data.birthday);
        }

/*
        if (data.phone && data.phone.length === 12) {
          this.disabledFields.push('phone');
          this.formData.phone = data.phone.slice(2);
        }
*/

        if (data.iin) {
          this.disabledFields.push('iin');
          this.formData.iin = data.iin;
        }

        if (data.kz_id_number) {
          this.disabledFields.push('kz_id_number');
          this.formData.identityNumber = data.kz_id_number;
        }

        this._cdRef.markForCheck();
      });
  }

  next(): void {
    switch (this.step) {
      case 0:
        if (!this.formData.is2fa) {
          this._dialog.open(AlertDialogComponent, {
            width: '750px',
            data: {
              title: 'PROFILE.DOCUMENT_FLOW.NOTIFICATION',
              close: 'PROFILE.DOCUMENT_FLOW.ENABLE_2FA',
              description: this._translateService.instant('PROFILE.DOCUMENT_FLOW.2FA_NEEDED')
            }
          }).afterClosed()
            .subscribe(result => {
              if (result) {
                this._router.navigate(['user-settings/two-factor-auth-settings']);
              }
            });
          return;
        }

        if (!this.formData.isEbClient) {
          this._dialog.open(EbAppComponent, { width: '750px' });
          return;
        }

        this.step++;
        break;

      case 1:
      case 2:
        this.step++;
        break;

      case 3:
        this._dialog
          .open(TwofaCheckComponent, { width: '750px' })
          .afterClosed()
          .subscribe(result => {
            if (result) {
              this.saveDocument();
            }
          });
        break;
    }

  }

  uploadFile(files: FileList, prop: keyof DocumentFlowData): void {
    const fileToUpload = files.item(0);

    if (!fileToUpload) {
      return;
    }

    const formData = new FormData();
    //formData.set('type', 'form');
    formData.set('document', fileToUpload as any);

    this.waitForUpload = true;
    this._cdRef.markForCheck();
    this._dataService.uploadDocument(formData)
      .subscribe(result => {
        this.formData[prop] = result.data.fileName as never;
        this._cdRef.markForCheck();
      }, error => {
        console.log(error);
      })
      .add(() => {
        this.waitForUpload = false;
        this._cdRef.markForCheck();
      });
  }

  saveDocument(): void {
    this._dataService
      .saveDocument(this.formData)
      .subscribe(result => {
        this._dialog.open(AlertDialogComponent, {
          width: '750px',
          data: {
            title: 'PROFILE.DOCUMENT_FLOW.NOTIFICATION',
            close: 'PROFILE.DOCUMENT_FLOW.BACK_TO_PROFILE',
            description: this._translateService.instant('PROFILE.DOCUMENT_FLOW.CONGRATULATION')
          }
        }).afterClosed()
          .subscribe(_ => {
            this._router.navigate(['user-settings']);
          });
      });
  }
}
