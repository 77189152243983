import {Directive, forwardRef, HostBinding, Input, OnChanges, StaticProvider} from '@angular/core';
import {NG_VALIDATORS, ValidatorFn} from '@angular/forms';
import {AbstractValidatorDirective, maxValidator, toFloat} from './abstract-validator.directive';

export const BIX_MAX_VALIDATOR: StaticProvider = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => MaxDirective),
  multi: true
};

@Directive({
  selector: 'input[appMax][formControlName],input[appMax][formControl],input[appMax][ngModel],',
  providers: [BIX_MAX_VALIDATOR]
})
export class MaxDirective extends AbstractValidatorDirective implements OnChanges {
  @HostBinding('attr.appMax')
  @Input() appMax!: string|number|null;
  inputName = 'appMax';
  normalizeInput = (input: string|number): number => toFloat(input);
  createValidator = (max: number): ValidatorFn => maxValidator(max);
}
