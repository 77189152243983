import {Component, OnDestroy} from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { DataService } from '../../../services/data.service';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-two-factor-auth',
  templateUrl: './two-factor-auth.component.html',
  styleUrls: ['./two-factor-auth.component.scss']
})
export class TwoFactorAuthComponent implements OnDestroy {
  public isLightTheme = false;
  public displayInitial: boolean;
  public displayEnabled: boolean;
  public displayDisable: boolean;
  public displayPending: boolean;
  public isLoading = false;
  public qrCode: string;
  public key64: string;
  public key6: string;
  public errors = {
    tFaInvalid: false
  };
  destroySubject$: Subject<void> = new Subject();
  constructor(private authService: AuthService,
              private dataService: DataService) {
    if (this.authService.isLoggedIn) {
      this.dataService.getUserProfile()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(res => {
          if (res['2fa_enabled']) {
            this.displayDisable = true;
          } else {
            this.displayInitial = true;
            this.twoFaSetup();
          }
        });
    }
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }
  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  public enable2Fa(): void {
    this.isLoading = true;
    this.clearErrors();
    this.authService.toggle2Fa(this.key6)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        this.displayInitial = false;
        this.displayEnabled = true;
        this.isLoading = false;
      }, err => this.handleError(err));
  }
  public disable2Fa(): void {
    this.isLoading = true;
    this.clearErrors();
    this.authService.toggle2Fa(this.key6)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        this.displayInitial = true;
        this.displayDisable = false;
        this.key6 = '';
        this.twoFaSetup();
        this.isLoading = false;
      }, err => {
        this.handleError(err);
      });
  }
  public copyToClipboard(text): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    document.querySelector('.two-factor-auth-button').classList.add('two-factor-auth-button--clicked');
  }
  private clearErrors() {
    Object.keys(this.errors).forEach(v => this.errors[v] = false);
  }
  private handleError(err) {
    console.log(err);
    if (err.error.errorCode === '401_7') {
      this.errors.tFaInvalid = true;
    }
    this.isLoading = false;
  }
  private twoFaSetup(): void {
    this.isLoading = true;
    this.authService.twoFaSetup()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(response => {
        // console.log('twoFaSetup response', response);
        this.key64 = response.key;
        this.qrCode = response.qrImageUrl;
        this.isLoading = false;
      });
  }
}
