import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {SvgService} from '../../../services/svg.service';
import {
  OrderbooksHistoryMarketInterface,
  OrderbooksHistoryMarketModel,
  OrderbooksMarketInterface
} from '../../../models/orderbooks-history-market.model';
import {SocketDataClass} from '../../../models/socket-data.class';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'app-market-depth',
  templateUrl: './market-depth.component.html',
  styleUrls: ['./market-depth.component.scss']
})
export class MarketDepthComponent implements OnInit, OnDestroy {
  @Input() public isMinimized: boolean;
  @Output() public minimized = new EventEmitter<any>();
  public isLightTheme = false;
  public orderBookData: OrderbooksHistoryMarketInterface;
  public orderBookDataSpliced: any = {};
  public Math: any;
  public isVisibleActualPair = false;
  destroySubject$: Subject<void> = new Subject();

  constructor(public dataService: DataService,
              public svgService: SvgService) {
    this.Math = Math;
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }

  public ngOnInit (): void {
    this.initialDataLoading();
    this.dataService.getMarketPairNewEmitter()
      .subscribe(() => {
        try {
          this.isVisibleActualPair = true;
          this.initialDataLoading();
          setTimeout(() => this.isVisibleActualPair = false, 2000);
        } catch (e) {
          // console.log(e);
        }
      });
  }
  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  public initialDataLoading() {
    this.dataService.getOrderBookHistory()
      .subscribe((history: OrderbooksHistoryMarketInterface) => {
        // console.log(history);
        this.orderBookData = history;
        // Object.assign(this.orderBookDataSpliced, {buy: JSON.parse(JSON.stringify(this.orderBookData.buy)).splice(0, 11)});
        Object.assign(this.orderBookDataSpliced, {bid: JSON.parse(JSON.stringify(this.orderBookData.bid)).splice(0, 11)});
        // Object.assign(this.orderBookDataSpliced, {sell: JSON.parse(JSON.stringify(this.orderBookData.sell)).splice(0, 11)});
        Object.assign(this.orderBookDataSpliced, {ask: JSON.parse(JSON.stringify(this.orderBookData.ask)).splice(0, 11)});
        /*this.dataService.updateOrderBookData(this.orderBookData)*/

        this.dataService.SubscribeToOrderbookUpdates().subscribe((socketData) => {
          // console.log(socketData);
          this.orderBookData = this.addToOrderBookNewDates(socketData, this.orderBookData);
          this.orderBookData = this.updateOrderBookDates(socketData, this.orderBookData);
          this.orderBookData = this.deleteEmptyOrderBookDates(this.orderBookData);
          this.orderBookData = this.sortFormatedOrderBookDates(this.orderBookData);
          this.orderBookData = this.formatOrderBookDates(this.orderBookData);
          // Object.assign(this.orderBookDataSpliced, {buy: JSON.parse(JSON.stringify(this.orderBookData.buy)).splice(0, 11)});
          Object.assign(this.orderBookDataSpliced, {bid: JSON.parse(JSON.stringify(this.orderBookData.bid)).splice(0, 11)});
          // Object.assign(this.orderBookDataSpliced, {sell: JSON.parse(JSON.stringify(this.orderBookData.sell)).splice(0, 11)});
          Object.assign(this.orderBookDataSpliced, {ask: JSON.parse(JSON.stringify(this.orderBookData.ask)).splice(0, 11)});
          /*this.dataService.updateOrderBookData(this.orderBookData)*/
          // console.log(this.orderBookData);
          // console.log(this.orderBookDataSpliced);
        });
      });

  }

  public addToOrderBookNewDates(socketData: SocketDataClass,
                                marketDateHistory: OrderbooksHistoryMarketInterface): OrderbooksHistoryMarketInterface {
    const buys = socketData.Buys;
    const sells = socketData.Sells;
    const bidsMap = new Map<number, number>(marketDateHistory.bid.map((v) => [+v.Price, +v.Size]));
    let asksMap = new Map<number, number>(marketDateHistory.ask.map((v) => [+v.Price, +v.Size]));
    let bids = [...marketDateHistory.bid];
    let asks = [...marketDateHistory.ask];
    /*
        console.log('buys sells', {buys, sells})
    */

    if (buys && buys.length) {
      for (let i = 0; i < buys.length; i++) {
        if (buys[i].Quantity && buys[i].Quantity > 0 && buys[i].Rate && buys[i].Rate > 0) {
          const size = (bidsMap.get(+buys[i].Rate) || 0) + +buys[i].Quantity;
          bidsMap.set(+buys[i].Rate, size);
          asks = asks.filter((v) => v.Price > buys[i].Rate);
          /*
                    console.log('bid push', {Size: Size, Price: Price, Total: Total, Sum: Sum})
          */
        }
      }
      bids = Array.from(bidsMap.entries()).map(([Price, Size]) => ({Price, Size, Total: Size * Price, Sum: Size}));
      asksMap = new Map(asks.map((v) =>  [+v.Price, +v.Size]));
    }
    if (sells && sells.length) {
      for (let i = 0; i < sells.length; i++) {
        if (sells[i].Quantity && sells[i].Quantity > 0 && sells[i].Rate && sells[i].Rate > 0) {
          const size = (asksMap.get(+sells[i].Rate) || 0) + +sells[i].Quantity;
          asksMap.set(+sells[i].Rate, size);
          bids = bids.filter((v) => v.Price < +sells[i].Rate);
          /*
                    console.log('ask push', {Size: Size, Price: Price, Total: Total, Sum: Sum})
          */
        }
      }
      asks = Array.from(asksMap.entries()).map(([Price, Size]) => ({Price, Size, Total: Size * Price, Sum: Size}));
    }
    /*
        console.log('asks bids', {asks, bids});
    */
    return {...marketDateHistory, ask: asks, bid: bids};
  }




  public getTotalWidth(total, key) {
    return ((total / this.orderBookDataSpliced[key][this.orderBookDataSpliced[key].length - 1].Sum) * 100).toFixed(2) + '%';
  }

  public updateOrderBookDates(socketData, marketDateHistory): OrderbooksHistoryMarketInterface {
    const treadingTypeCurrent = Object.keys(socketData).splice(1, 2);
    // console.log(treadingTypeCurrent);
    const treadingTypeHistory = Object.keys(marketDateHistory);

    for (let i = 0; i < treadingTypeCurrent.length; i++) {
      socketData[treadingTypeCurrent[i]].forEach((currentOrderBook) => {

        const index = this.findOrderBook(marketDateHistory[treadingTypeHistory[i]], 'Price', 'Rate', currentOrderBook);

        if (index !== -1) {
          const historyOrderBook = marketDateHistory[treadingTypeHistory[i]][index];
          const updatedOrderBook = this.updateOrderBook(currentOrderBook, historyOrderBook);

          if (updatedOrderBook === null) {
            marketDateHistory[treadingTypeHistory[i]].splice(index, 1);
          } else {
            marketDateHistory[treadingTypeHistory[i]][index] = updatedOrderBook;
          }
        }
        return marketDateHistory;

      });
    }
    return marketDateHistory;
  }

  public updateOrderBook(currentOrderBook, historyOrderBook) {
    // console.log(currentOrderBook);
    switch (currentOrderBook.Type) {
      //ADD QUANTITY
      case 0 : {
        historyOrderBook.Size += currentOrderBook.Quantity;
        return historyOrderBook;
      }
      //REMOVE CURRENT
      case 1: {
        return null;
      }
      //UPDATE QUANTITY
      case 2: {
        return {
          Size: currentOrderBook.Quantity,
          Price: currentOrderBook.Rate
        };
      }
    }
  }

  public deleteEmptyOrderBookDates(marketData): OrderbooksHistoryMarketInterface {
    const sortedMarketData: OrderbooksHistoryMarketInterface = new OrderbooksHistoryMarketModel();

    for (const treadingType in marketData) {
      if (marketData.hasOwnProperty(treadingType)) {

        // if (treadingType === 'buy') {
        if (treadingType === 'bid') {
          sortedMarketData[treadingType] = marketData[treadingType].filter((data: OrderbooksMarketInterface) => {
            return data.Price && data.Size && data.Total;
          });
        }

        // if (treadingType === 'sell') {
        if (treadingType === 'ask') {
          sortedMarketData[treadingType] = marketData[treadingType].filter((data: OrderbooksMarketInterface) => {
            return data.Price && data.Size && data.Total;
          });
        }
      }
    }
    // console.log(sortedMarketData);
    return sortedMarketData;
  }

  public sortFormatedOrderBookDates(marketData): OrderbooksHistoryMarketInterface {
    const sortedMarketData: OrderbooksHistoryMarketInterface = new OrderbooksHistoryMarketModel();
    for (const treadingType in marketData) {
      if (marketData.hasOwnProperty(treadingType)) {
        // if (treadingType === 'buy') {
        if (treadingType === 'bid') {
          sortedMarketData[treadingType] = marketData[treadingType].sort((a, b) => {
            if (a.Price > b.Price) {
              return -1;
            }
            if (a.Price < b.Price) {
              return 1;
            }
          });
        }

        // if (treadingType === 'sell') {
        if (treadingType === 'ask') {
          sortedMarketData[treadingType] = marketData[treadingType].sort((a, b) => {
            if (a.Price > b.Price) {
              return 1;
            }
            if (a.Price < b.Price) {
              return -1;
            }
          });
        }
      }
    }
    return sortedMarketData;
  }

  public formatOrderBookDates(marketData: OrderbooksHistoryMarketInterface): OrderbooksHistoryMarketInterface {
    const newMarketData: OrderbooksHistoryMarketInterface = new OrderbooksHistoryMarketModel();

    for (const treadingType in marketData) {
      if (marketData.hasOwnProperty(treadingType)) {
        newMarketData[treadingType] = marketData[treadingType].reduce((dateAccumulator, date, i) => {
          if (i === 0) {
            dateAccumulator.push({
              Size: date.Size,
              Price: date.Price,
              Total: date.Size * date.Price,
              Sum: date.Size
            });
          } else {
            dateAccumulator.push({
              Size: date.Size,
              Price: date.Price,
              Total: date.Size * date.Price,
              Sum: dateAccumulator[dateAccumulator.length - 1].Sum + date.Size
            });
          }
          return dateAccumulator;
        }, []);
      }
    }
    return newMarketData;
  }

  public findOrderBook(array, fieldOld, fieldNew, value) {
    for (let i = 0; i < array.length; i++) {
      if (array[i][fieldOld] === value[fieldNew]) {
        return i;
      }
    }
    return -1;
  }
}
