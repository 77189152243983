import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderHistoryFilter'
})
export class OrderHistoryFilterPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (args === 'all') {
      return value;
    } else if (args === 'sell') {
      return value.filter( v => {
        return v.type.includes('SELL');
      });
    } else if (args === 'buy') {
      return value.filter( v => {
        return v.type.includes('BUY');
      });
    }
    return value;
  }
}
