import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    console.log('redirect guard', route.data['externalUrl']);

    let url = route.data['externalUrl'];
    if (route.queryParams.check) {
      url = `${url}&check=true`;
    }
    window.location.href = url;
      return true;
  }
}
