import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'homePlus'
})
export class HomePlusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value > 0) {
      return `+${value}`;
    } else if (Number.isNaN(+value)) {
      return `0.00`;
    }

    return value;
  }

}
