import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {Subject} from 'rxjs/internal/Subject';
import {ThemeService} from '../../services/theme.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy {

  partnersClass = 'partners-carousel';
  destroySubject$: Subject<void> = new Subject();
  constructor(public dataService: DataService, public themeService: ThemeService) {
  }

  ngOnInit(): void {
    this.dataService.getUserProfile().toPromise();
  }

  ngOnDestroy(): void {
    this.destroySubject$.next();
  }

}
