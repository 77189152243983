import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'feesAndLimitsSearch'
})
export class FeesAndLimitsSearchPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (args) { args = args.toUpperCase(); } else { return value; }

    return value.filter(item => {
      return item.name.toUpperCase().includes(args) || item.symbol.toUpperCase().includes(args);
    });
  }

}
