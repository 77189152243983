import {Directive, EventEmitter, HostListener, Output} from '@angular/core';
import {split} from 'ts-node';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs/internal/Subscription';

@Directive({
  selector: '[appMouseWheel]'
})
export class MouseWheelDirective {
  private subscription: Subscription;
  @Output() mouseWheelUp = new EventEmitter();
  @Output() mouseWheelDown = new EventEmitter();

  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    this.mouseWheelFunc(event);
  }

  @HostListener('mouseup', ['$event']) onMouseClick(event: any) {
    this.mouseWheelFunc(event);
  }

  constructor(private router: Router,
              private activateRoute: ActivatedRoute) {
  }

  public mouseWheelFunc(_event: any) {
    const event = window.event || _event; // old IE support

    if (event && (event.which === 2 || event.button === 4 )) {
      event.preventDefault();
      // const href = (<any>this.activateRoute.snapshot)._routerState.url;
      const URL = event.target.href
        || event.target.parentElement.href
        || event.target.parentElement.parentElement.href
        || event.target.parentElement.parentElement.parentElement.href;
      const strWindowFeatures = 'location=yes,height=570,width=520,scrollbars=yes,status=yes';
      // const URL = 'https://trade.biteeu.com/trades' + location.href;
      // const win = window.open(URL, '_blank', strWindowFeatures);
      if (URL && URL.length > 4) {
        // window.open(URL, '_blank');
      }
      // console.log('===middleclicked ');
      // console.log(event.target);
      // console.log(event);

      // console.log((<any>this.activateRoute.snapshot)._routerState.url);
      // console.log(this.activateRoute.snapshot.params);
      // this.subscription = this.activateRoute.params
      //   .subscribe(params => {
      //     // this.reportId = params['report-id'];
      //     console.log(params);
      //   });
    }
  }

}
