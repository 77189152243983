import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  widget,
  ChartingLibraryWidgetOptions,
  LanguageCode, IChartingLibraryWidget,
} from '../../../../assets/tradingview/charting_library';
// import { UDFCompatibleDatafeed } from '../../../../assets/datafeeds/udf/lib/udf-compatible-datafeed';
import Datafeeds from '../../../../assets/tradingview/datafeeds/datafeed';
import Io from '../../../../assets/tradingview/charting_library/socket.js';
import { DataService } from '../../../services/data.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-chart-container',
  templateUrl: './chart-container.component.html',
  styleUrls: ['./chart-container.component.scss']
})
export class ChartContainerComponent implements OnInit, OnDestroy {

  private widget: IChartingLibraryWidget;
  private _symbol: ChartingLibraryWidgetOptions['symbol'] = '';
  private _interval: ChartingLibraryWidgetOptions['interval'] = '1D';
  // BEWARE: no trailing slash is expected in feed URL
  datafeed: any;
  dataCache: any = {};
  getBarTimer: any = null;
  // public chartProperties_ls: any;
  public ls_scalesProperties_textColor: string;
  // private _datafeedUrl = 'https://demo_feed.tradingview.com';
  private _libraryPath: ChartingLibraryWidgetOptions['library_path'] = '../../../../assets/tradingview/charting_library/';
  // private _chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'] = 'localStorageTV.js';
  // private _chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'] = '1.1';
  // private _clientId: ChartingLibraryWidgetOptions['client_id'] = 'tradingview.com';
  // private _userId: ChartingLibraryWidgetOptions['user_id'] = 'trade.biteeu.com';
  private _fullscreen: ChartingLibraryWidgetOptions['fullscreen'] = false;
  private _autosize: ChartingLibraryWidgetOptions['autosize'] = true;
  private _containerId: ChartingLibraryWidgetOptions['container_id'] = 'tv_chart_container';
  public isLoaded = false;
  public isLightTheme = false;
  public intervalId = null;
  public theme: ChartingLibraryWidgetOptions['theme'] = 'Dark';
  private intervals = [];

  private resolutions = {
    1: 'MINUTE_1',
    5: 'MINUTE_5',
    15: 'MINUTE_15',
    30: 'MINUTE_30',
    60: 'HOUR_1',
    120: 'HOUR_2',
    180: 'HOUR_3',
    240: 'HOUR_4',
    '1D': 'DAY_1',
    '3D': 'DAY_3',
    '1W': 'WEEK_1',
    '1M': 'MONTH_1',
  };

  destroySubject$: Subject<void> = new Subject();

  constructor(public dataService: DataService) {
    this.loadNewPair();
    this.loadTheme();
  }

  ngOnInit() {
    setTimeout(() => {
      this.loadData();
    }, 400);
  }

  ngOnDestroy() {
    this.clearChartData();
    for (const int of this.intervals) {
      this.unsubscribeBars(int);
    }
  }

  private loadTheme() {
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.theme = this.isLightTheme ? 'Light' : 'Dark';
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
        const theme = this.isLightTheme ? 'Light' : 'Dark';
        this.theme = theme;
        this.loadData();
      });
  }

  private loadNewPair() {
    this.clearChartData();
    this.dataService.getMarketPairNewEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
          const pair = this.dataService.getPair();
          this._symbol = pair;
          setTimeout(() => this.loadData(), 400);
          window.history.pushState('trades', '', `/trades/${this.dataService.getPair()}`);
          location.reload();
        });
  }

  private getLanguageFromURL(): LanguageCode | null {
    const regex = new RegExp('[\\?&]lang=([^&#]*)');
    const results = regex.exec(location.search);

    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode;
  }

  private getOptions(pair: string) {
    return {
      symbol: pair,
      container_id: this._containerId,
      library_path: this._libraryPath,
      datafeed: this.datafeed,
      custom_css_url: './custom.css',
      // datafeed: new UDFCompatibleDatafeed(this._datafeedUrl),
      interval: '1D',
      locale: this.getLanguageFromURL() || 'en',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone as ChartingLibraryWidgetOptions['timezone'],
      debug: false,
      load_last_chart: true,
      auto_save_delay: 5,
      enabled_features: [
        'header_widget',
        'header_saveload',
        'save_shortcut'
      ],
      disabled_features: [
        'use_localstorage_for_settings',
        'save_chart_properties_to_local_storage',
        'header_symbol_search',
      ],

      // charts_storage_url: this._chartsStorageUrl,
      // charts_storage_api_version: this._chartsStorageApiVersion,
      // client_id: this._clientId,
      // user_id: this._userId,
      fullscreen: this._fullscreen,
      autosize: this._autosize,
      loading_screen: {
        foregroundColor: '#9a9ca5',
        backgroundColor: '#0A2240'
      },
      toolbar_bg: this.theme === 'Dark' ? '#0A2240' : '#ffffff',
      overrides: {
        'paneProperties.background': this.theme === 'Dark' ? '#0A2240' : '#ffffff',
        'paneProperties.vertGridProperties.color': '#131722',
        'paneProperties.horzGridProperties.color': '#131722',
        // 'symbolWatermarkProperties.transparency': 0,
        // 'scalesProperties.backgroundColor' : '#000',
        'scalesProperties.textColor': '#9a9ca5'
      },
      theme: this.theme,
    };
  }

  public loadData() {
    const pair = this.dataService.getPair();
    Io.setSymbol(pair);
    this.datafeed = new Datafeeds(this, this._interval);

    // this.dataService.SubscribeToCandleUpdates()
    // .subscribe(data => {
      //   console.log(data);
      // });

    const widgetOptions = this.getOptions(pair);
    this.widget = new widget(widgetOptions);

    this.widget.onChartReady(() => {
      this.dataService.emitCandleUpdates({marketSymbol: pair, candleInterval: this.widget.activeChart().resolution()});

      const savedChart = JSON.parse(localStorage.getItem(pair));

      if (savedChart) {
        this.widget.load(savedChart);
        this.widget.applyOverrides({
          'paneProperties.background': this.theme === 'Dark' ? '#0A2240' : '#ffffff',
        });
      }

      // this.widget.activeChart().createStudy('Compare', false, false, ["open", 'AAPL']);

      this.widget.onShortcut('shift+s', () => {
        this.widget?.save((chart) => {
          localStorage.setItem(pair, JSON.stringify(chart));
        });
      });

      this.widget.activeChart().onIntervalChanged().subscribe(null, (interval) => {
        this.dataService.emitCandleUpdates({marketSymbol: pair, candleInterval: interval});
        this.intervals.push(interval);

        if (this.intervals.length > 1) {
          this.unsubscribeBars(this.intervals.shift());
        }
      });

      setInterval((() => {
        this.intervalId = this.widget?.save((chart) => {
          if (chart) {
          localStorage.setItem(pair, JSON.stringify(chart));
          }
        });
      }).bind(this), 2000);
    });


  }

  public clearChartData() {
    if (this.widget) {
      this.widget.remove();
    }
    this.widget = null;
    Io.clear();
    if (this.getBarTimer) {
      clearTimeout(this.getBarTimer);
    }
    if (this.intervalId) {
      clearTimeout(this.intervalId);
    }
  }

  searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
    console.log('mgr search symbols');
    this.dataService.marketsDetails$.pipe(takeUntil(this.destroySubject$)).subscribe(
      (data) => onResultReadyCallback(data.map(
        (market) => ({
          symbol: market.MarketName,
          full_name: market.MarketFullName,
          description: '',
          exchange: market.MarketName.split('-')[0],
          ticker: market.Last,
          type: 'bitcoin',
        })
      ))
    );
  }

  getBars(symbol, resolution, from, to, callback) {
    // symbol = this.dataService.getPair();
    let data;

    const symbolData = this.dataCache[symbol];
    if (symbolData) {
      data = symbolData[resolution];
    }
    if (resolution === 'D') {
      resolution = '1D';
    }

    if (this.getBarTimer) {
      clearTimeout(this.getBarTimer);
    }

    // if (this._symbol && symbol != this._symbol) {
    //   symbol = this._symbol;
    // }

    const fromMs = from * 1000;
    const toMs = to * 1000;

    // 取缓存数据 // Данные кэша
    const fetchCacheData = data => {
      const newBars = [];
      let count = 0;
      data.forEach((element) => {
        const barTime = element.time;
        if (barTime >= fromMs && barTime <= toMs) {
          newBars.push(element);
          count++;
        }
      }, this);

      if (count > 0) {
        newBars.sort((l, r) => l.time > r.time ? 1 : -1);
        callback && callback({ s: 'ok', bars: newBars });
      } else {
        callback && callback({ s: 'no_data' });
      }
      const params = {
        resolution: resolution,
        symbol: symbol,
        type: 'update',
        from: from,
        to: to
      };
      Io.subscribeKline(params, this.onUpdateData.bind(this));
    };

    // 请求数据 /// Запрос данных
    const requestData = () => {
      const params = {
        resolution: resolution,
        symbol: symbol,
        type: 'kline',
        from: from,
        to: to
      };
      Io.subscribeKline(params, this.onUpdateData.bind(this));

      this.getBarTimer = setTimeout(() => {
        this.getBars(symbol, resolution, from, to, callback);
      }, 400);
    };

    data ? fetchCacheData(data) : requestData();
    // requestData();
  }

  subscriptionItem: any = {};

  subscribeBars(symbolInfo,
                resolution,
                onRealtimeCallback,
                subscribeUID,
                onResetCacheNeededCallback,
                lastDailyBar) {
    const handler = {
      id: subscribeUID,
      callback: onRealtimeCallback,
    };
    this.subscriptionItem = {
      subscribeUID,
      resolution,
      lastDailyBar,
      handlers: [handler],
    };
    const currentResolution = this.widget.activeChart().resolution();
    const currentResolutionConverted = this.resolutions[currentResolution];

    return this.dataService.SubscribeToCandleUpdates(currentResolutionConverted);
  }

  unsubscribeBars(interval) {
    this.dataService.emitUnsubscribeCandleUpdates({marketSymbol: this.dataService.getPair(), candleInterval: interval});
  }

  getConfig() {
    // https://b.aitrade.ga/books/tradingview/book/UDF.html
    return {
      supports_search: true,  //  请修改datafeed的searchSymbols函数
      supports_group_request: false, // 设置为true将无法进行单个商品解析
      supports_marks: true,  // 请修改datafeed的getMarks函数
      supports_timescale_marks: true, // 请修改datafeed的getTimescaleMarks函数
      supports_time: true
    };
  }

  getServerTime() {
    // 暂时由客户端生成时间  // Временно генерируется клиентом
    return parseInt((+Date.now() / 1000).toFixed(0));
  }

  resolveTVSymbol(symbol) {
    // https://b.aitrade.ga/books/tradingview/book/Symbology.html
    return {
      'name': this._symbol,
      'exchange-traded': '',
      'exchange-listed': '',
      'timezone': 'Asia/Dubai',
      'mainSeriesProperties.style': 3,
      'minmov': 1,
      'minmov2': 0,
      'pointvalue': 1,
      'fractional': false,
      'session': '24x7',
      'has_intraday': true,
      'intraday_multipliers': ['1', '5', '15', '30', '60', '120', '180', '240'],
      'has_no_volume': true,
      'has_fractional_volume': true,
      'description': this._symbol,
      'pricescale': 100000000,
      'ticker': symbol,
      'supported_resolutions': ['1', '5', '15', '30', '60', '120', '180', '240', '1D', '3D', '1W', '1M']
    };
  }

  onUpdateData(data) {
    console.log('on update data', data)
    // data为服务端websocket返回的数据
    if (!data.kline) {
      return false;
    }
    if (!this.dataCache[data.symbol]) {
      this.dataCache[data.symbol] = {};
    }
    if (!this.dataCache[data.symbol][data.resolution]) {
      this.dataCache[data.symbol][data.resolution] = [];
    }
    if (data.type === 'kline' && data.kline.length) {
/*
      console.log('kline', data)
*/
      data.kline.forEach(elm => {
        this.dataCache[data.symbol][data.resolution].push({...elm, symbol: data.symbol});
      });
    }
    if (data.type === 'update' && data.kline) {
/*
      console.log('update', data)
*/

      this.dataCache[data.symbol][data.resolution].push(data.kline);
      this.dataCache = {...this.dataCache};
      console.log('on update data cache', this.dataCache)

      this.subscriptionItem.lastDailyBar = data.kline;
      this.subscriptionItem.handlers?.forEach(handler => handler.callback(data.kline));
    }
  }

  // public reloadTraidingViewWithNewPair() {
  //   const element = document.getElementById('tv_chart_container');
  //   const parentEl = element.parentNode;

  //   const siblingEl = document.getElementsByClassName('tradingview-widget-copyright')[0];
  //   const divWidget = document.createElement('div');
  //   divWidget.id = 'tv_chart_container';
  //   parentEl.insertBefore(divWidget, siblingEl);

  //   const pair = this.dataService.getPair().split('-').reverse().join('');
  //   this.widgetOptions = this.getOptions(pair);
  //   this.widget = new widget(this.widgetOptions);
  // }

  // public initialDataLoadingFirst() {
  //   this.chartProperties_ls = JSON.parse(localStorage.getItem('tradingview.chartproperties'));
  //   this.ls_scalesProperties_textColor = (this.chartProperties_ls && this.chartProperties_ls.scalesProperties
  //     && this.chartProperties_ls.scalesProperties.textColor) ? this.chartProperties_ls.scalesProperties.textColor : '';
  // }

}
