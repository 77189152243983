export class ShippingDetails {
    id: number;
    fullName: string;
    country: string;
    city: string;
    state: string;
    addressInfo: string;
    additionalAddressInfo: string;
    postCode: number;
    email: string;
    phoneNumber: string;

    constructor (
        id: number,
        fullName: string,
        country: string,
        city: string,
        state: string,
        addressInfo: string,
        additionalAddressInfo: string,
        postCode: number,
        email: string,
        phoneNumber: string,
    ) {
        this.fullName = fullName;
        this.country = country;
        this.city = city;
        this.state = state;
        this.addressInfo = addressInfo;
        this.additionalAddressInfo = additionalAddressInfo;
        this.postCode = postCode;
        this.email = email;
        this.phoneNumber = phoneNumber;
        this.id = id;
    }
}