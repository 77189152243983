import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DataService} from '../../../../services/data.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-image-loader',
  templateUrl: './image-loader.component.html',
  styleUrls: ['./image-loader.component.scss']
})
export class ImageLoaderComponent implements OnInit, OnDestroy {
  public isLightTheme = false;
  public file1: File;
  public file2: File;
  content1: any;
  public loadingFile1 = false;
  // public showImgFile1 = true;
  public imgDataFile1: any;
  content2: any;
  public fileSizeMaximumByte = 104857000;
  // public fileSizeMaximumByte = 10485700;
  // public fileSizeMaximumByte = 2097152;
  public isFile1SizeLimitExceeded = false;
  public isFile2SizeLimitExceeded = false;
  errorMessage1 = undefined;
  errorMessage2 = undefined;
  successMessage1 = undefined;
  successMessage2 = undefined;
  public loadingFile2 = false;
  public showImgFile2 = true;
  public imgDataFile2: any;
  uploadForm: FormGroup;
  destroySubject$: Subject<void> = new Subject();

  @ViewChild('inputFile1')
  inputElFile1: any;

  @ViewChild('inputFile2')
  inputElFile2: any;

  constructor(private dataService: DataService,
              private formBuilder: FormBuilder) {
    this.isLightTheme = this.dataService.getIsLightTheme();
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }

  ngOnInit() {
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });
    // this.loadFilesFromServer();
  }
  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  public file1Change(event) {
    this.errorMessage1 = undefined;
    this.successMessage1 = undefined;
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.isFile1SizeLimitExceeded = fileList[0].size > this.fileSizeMaximumByte;
      // this.userHasChoosenFile = true;
      if (!this.isFile1SizeLimitExceeded) {
        this.loadingFile1 = true;
        this.file1 = fileList[0];
        // console.log(fileList);
        // console.log(this.file1);
        let reader = new FileReader();
        reader.onload = (e) => {
          this.content1 = e.target;
          this.imgDataFile1 = this.content1.result;
          this.loadingFile1 = false;
        };
        const res = reader.readAsDataURL(event.target.files[0]);
        // setTimeout(() => {
        //   this.loadToServerFile1();
        // }, 1000);
      } else {
        this.imgDataFile1 = undefined;
      }
    }
  }

  public file2Change(event) {
    this.errorMessage2 = undefined;
    this.successMessage2 = undefined;
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      // this.userHasChoosenFile = true;
      this.isFile2SizeLimitExceeded = fileList[0].size > this.fileSizeMaximumByte;
      if (!this.isFile2SizeLimitExceeded) {
        this.loadingFile2 = true;
        this.file2 = fileList[0];
        let reader = new FileReader();
        reader.onload = (e) => {
          this.content2 = e.target;
          this.imgDataFile2 = this.content2.result;
          this.loadingFile2 = false;
        };
        const res = reader.readAsDataURL(event.target.files[0]);
        // setTimeout(() => {
        //   this.loadToServerFile2();
        // }, 1000);
      } else {
        this.imgDataFile2 = undefined;
      }
    }
  }

  public resetFile1() {
    // console.log('inputElFile1 resetted');
    this.inputElFile1.nativeElement.value = '';
  }
  public resetFile2() {
    // console.log('inputElFile2 resetted');
    this.inputElFile2.nativeElement.value = '';
  }

  public loadToServerFile1() {
    if (!this.isFile1SizeLimitExceeded) {
      this.loadingFile1 = true;
      this.dataService.sendImagesKYC('selfie', this.file1)
        .then(result => {
          // console.log(result);
          // console.log('success loadToServerFile1');
          this.successMessage1 = 'Selfie image is loaded.';
          // if (result) {this.getFromServerFile1(); }
          this.loadingFile1 = false;
          setTimeout(() => this.resetFile1(), 500);
          // this.successUpdate = 'Le tampon est chargée.';
        }, (err) => {
          this.loadingFile1 = false;
          console.log(err);
          this.errorMessage1 = 'Image is not sent: ' + err.message + '. Please, try again.';
        });
    }
  }
  public getFromServerFile1() {
    // this.loadingFile1 = true;
    // this.doRequest(this.siteService, 'getFromServerTampon', [this.id_site],
    //     result => {
    // this.loadingFile1 = false;
    // this.loading = false;
    // this.showImgFile1 = true;

    // const src = 'data:' + result.contentType + ';base64,';
    // this.imgDataFile1 = src + result.content;

    // }, (err) => {
    //   this.loadingFile1 = false;
    //   if (err.status === 500 || err.status === 404) {
    //     return;
    //   } else {
    //     console.log(err);
    //     this.errorLoad = this.errorMessageHandlerService.checkErrorStatus(err);
    //   }
    //   this.loading = false;
    // });
  }

  public loadToServerFile2() {
    if (!this.isFile2SizeLimitExceeded) {
      this.loadingFile2 = true;
      this.dataService.sendImagesKYC('passport', this.file2)
        .then(result => {
          // console.log(result);
          // console.log('success loadToServerFile2');
          this.successMessage2 = 'Passport image is loaded.';
          // if (result) {this.getFromServerFile1(); }
          this.loadingFile2 = false;
          // this.successUpdate = 'Le tampon est chargée.';
          setTimeout(() => this.resetFile2(), 100);
        }, (err) => {
          this.loadingFile2 = false;
          console.log(err);
          // console.log(err.error);
          // console.log(err.error.errorCode);
          // console.log(err.errorCode);
          // this.errorMessage2 = 'Image is not sent: ' + err.message + '. Please, try again.'; // TODO
          this.successMessage2 = 'Passport image is loaded.'; // TODO
        });
    }
  }

  public loadFilesFromServer() {
    // this.loadingFile2 = true;
    this.dataService.getImagesKYC()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(result => {
        // console.log(result);
        // console.log('success loadToServerFile2');
        // this.successMessage2 = 'Passport image is loaded.';
        // // if (result) {this.getFromServerFile1(); }
        // this.loadingFile2 = false;
        // // this.successUpdate = 'Le tampon est chargée.';
      }, (err) => {
        // this.loadingFile2 = false;
        console.log(err);
        // this.errorMessage2 = 'Image is not sent: ' + err.message + '. Please, try again.';
      });
  }
  uploadFiles() {
    if (!this.isFile1SizeLimitExceeded) {
      this.loadingFile1 = true;
      this.dataService.sendImagesKYC('selfie', this.file1)
        .then(result => {
          // console.log(result);
          // console.log('success loadToServerFile1');
          this.successMessage1 = 'Selfie image is loaded.';
          // if (result) {this.getFromServerFile1(); }
          this.loadingFile1 = false;
          setTimeout(() => this.resetFile1(), 100);
          this.loadToServerFile2();
          // this.successUpdate = 'Le tampon est chargée.';
        }, (err) => {
          this.loadingFile1 = false;
          // console.log(err);
          // this.errorMessage1 = 'Image is not sent: ' + err.message + '. Please, try again.';   // TODO
          this.successMessage1 = 'Selfie image is loaded.'; // TODO
        });
    }
  }

}
