export const ru = {
    header: {
        navigation: [
            {
                id: 1,
                url: 'https://iampartof.org/',
                name: 'IAMPARTOF - БЛАГОТВОРИТЕЛЬНОСТЬ',
            },
            {
                id: 2,
                url: '/tutorial',
                name: 'РУКОВОДСТВО',
            },
            {
                id: 3,
                url: 'http://trade.biteeu.com/login/ru',
                name: 'ВХОД',
            },
            {
                id: 4,
                url: 'http://trade.biteeu.com/sign-up/ru',
                name: 'РЕГИСТРАЦИЯ',
            },
        ],
    },
    mobileView: 'Мобильное приложениe',
    home: {
        promotionSection: {
            banner2: {
                title: 'Мы рады объявить о партнерстве с регулируемой благотворительной организацией Великобритании',
                buttonText: 'ПЕРЕЙТИ',
            },
        },
        chartSection: {
            title: 'BITEEU - глобальное становится доступным.',
            description: 'Лицензированная биржа виртуальных валют из Европейского Союза, отвечающая всем законодательным требованиям.',
            register: 'Зарегистрироваться',
            explore: 'Изучить рынки',
        },
        partnersSection: 'Партнеры',
        featuresSection: {
            title: 'Главные особенности',
            features: [
                {
                    id: 1,
                    src: 'images/features/safety.svg',
                    title: 'Безопасность',
                    subtitle: 'Наш главный приоритет – это безопасность Ваших средств и персональных данных. Вся информация хранится на удаленных серверах и защищена 24/7.',
                },
                {
                    id: 2,
                    src: 'images/features/platform.svg',
                    title: 'Поддержка различных платформ',
                    subtitle: 'Web, iOS, Android, Windows, MacOS.',
                },
                {
                    id: 3,
                    src: 'images/features/comission.svg',
                    title: 'Конкурентные комиссии',
                    subtitle: 'Конкурентные комиссии, как за ввод, так и за вывод средств. Структура тарифов прозрачна и не имеет скрытых комиссий.',
                },
                {
                    id: 4,
                    src: 'images/features/multilingual.svg',
                    title: 'Многоязычная поддержка 24/7',
                    subtitle: 'Наша служба поддержки обладает юридическими и техническими знаниями и готова вам помочь на разных языках, включая английский, турецкий и китайский.',
                },
                {
                    id: 5,
                    src: 'images/features/chart-f.svg',
                    title: 'Профессиональные торговые и технические инструменты',
                    subtitle: 'Полностью настраиваемый и гибкий график TradingView с передовыми торговыми инструментами.',
                },
                {
                    id: 6,
                    src: 'images/features/legal.svg',
                    title: 'Легальная и соответствующая всем законодательным требованиям',
                    subtitle: 'Наша биржа лицензирована и соответствует местным законам и требованиям Регулятора.',
                },
            ],
        },
        tradingAppSection: {
            title: 'Мобильное приложение',
        },
    },
    tutorial: {
        title: 'BITEEU обучающие видео:',
    },
    about: {
        title: 'О нас',
        texts: [
            'BITEEU DCX OÜ была основана в 2019 году в Эстонской Республике при участии профессионалов с большим опытом работы в банковском секторе, фондовом рынке и в международных публичных компаниях. Это лицензированная и регулируемая виртуальная валютная биржа в Европейском Союзе. Мы высоко ценим честность, безопасность и надежность, поэтому мы разработали нашу Политику по борьбе с отмыванием денег и Политику «Знай своего клиента» в соответствии со строгими требованиями местного законодательства и международных актов.',
            'BITEEU DCX OÜ сотрудничает с Bittrex для интеграции и внедрения самых передовых технологий в области безопасности, надежности и удобства. Наша платформа поддерживает несколько языков, включая английский, русский и китайский. Платформа обеспечивает круглосуточное обслуживание клиентов и безопасность Ваших активов и персональных данных.',
            'На данный момент система кошельков BITEEU DCX OÜ поддерживает более 50 виртуальных валют, и на регулярной основе будет в будущем дополнять Платформу новыми виртуальными валютами, благодаря партнерству с Bittrex.',
        ],
    },
    footer: {
        socials: [
            {
                id: 1,
                href: '/twitter',
                label: 'Follow us on Twitter',
                src: 'assets/img/socials/twitter-sm.svg',
            },
            {
                id: 2,
                href: '/instagram',
                label: 'Follow us on Instagram',
                src: 'assets/img/socials/insta-sm.svg',
            },
            {
                id: 4,
                href: '/telegram',
                label: 'Follow us on Telegram',
                src: 'assets/img/socials/telegram-sm.svg',
            },
        ],
        usefulLinks: [
            {
              id: 10,
              href: '/about',
              label: 'О нас',
            },
            {
              id: 11,
              href: '/about',
              label: 'Продукты',
            },
            {
                id: 1,
                href: '/docs/ippolicy',
                label: 'Политика Cookie',
            },
            {
                id: 2,
                href: '/docs/privacyestonia',
                label: 'Политика конфиденциальности',
            },
            {
                id: 3,
                href: '/docs/termsofuseestonia',
                label: 'Условия пользования',
            },
            {
                id: 4,
                href: '/about',
                label: 'О нас',
            },
            {
                id: 5,
                href: '/fees-and-limits',
                label: 'Комиссии и лимиты',
            },
            {
                id: 6,
                href: 'faq',
                label: 'FAQ',
            },
            {
                id: 7,
                href: '/public-api',
                label: 'Публичный API',
            },
            {
                id: 8,
                href: '/wallet-status',
                label: 'Статус кошельков',
            },
        ],
        asSeen: 'Как видно в:',
    },
};
