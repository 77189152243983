import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {ProfileState} from '../../store/profile.state';
import {TokensModel} from '../../models/tokens.model';

@Component({
  selector: 'app-receive-auth-token',
  templateUrl: './receive-auth-token.component.html',
  styleUrls: ['./receive-auth-token.component.css']
})
export class ReceiveAuthTokenComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private profileState: ProfileState,
    private router: Router
  ) {
    console.log('recieve auth token')
    const token = this.route.snapshot.paramMap.get('token');
    if (token) {
      const tokensModel = new TokensModel(JSON.parse(token));
      this.profileState.clearUserProfile();
      authService.storeToken(tokensModel);
      this.profileState.loadUserProfile();
      const lastPath = localStorage.getItem('lastPath');
      this.router.navigateByUrl(lastPath || '/');
    }
  }

  ngOnInit(): void {
  }

}
