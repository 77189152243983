import {Component, OnDestroy, OnInit} from '@angular/core';
import { DataService } from '../../../services/data.service';
import {AuthService} from '../../../services/auth.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  public isLightTheme = false;
  public bitNewsLetter: boolean;
  public isVisibleTips = false;
  public withdrawalEmail: boolean;
  public authEmail: boolean;
  destroySubject$: Subject<void> = new Subject();

  constructor(private dataService: DataService,
              public authService: AuthService) {
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }
  ngOnInit() {
    if (this.authService.isLoggedIn) {
      this.dataService.getUserProfile()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(res => {
          this.bitNewsLetter = false; // field is not presented in data yet, to be fixed
          this.withdrawalEmail = res.withdrawals_mail_enable;
          this.authEmail = res.email_verification_enabled;
        });
    }
  }
  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  public updateNotifications() {
    if (this.authService.isLoggedIn) {
      const data = {
        email_verification_enabled: this.authEmail,
        withdrawals_mail_enable: this.withdrawalEmail,
        bittrex_newsletter: this.bitNewsLetter
      };
      this.dataService.updateProfile(data)
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(() => {},
          err => {console.error(err); } );
    }
  }

  public toggleTips() {
    this.isVisibleTips = !this.isVisibleTips;
  }
}
