import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {Subject} from 'rxjs/internal/Subject';
import {AuthService} from '../../services/auth.service';
import {RestrictedCountriesClass} from '../../models/restricted-countries.class';
import {RESTRICTED_COUNTRIES} from '../../app.constants';
import {Router} from '@angular/router';
import {fromMobx} from '../../store/rx-from-mobx';
import {GeoState} from '../../store/geo.state';
import {ProfileState} from '../../store/profile.state';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  public isLightTheme = false;
  public userId: number;
  public userCountry = new RestrictedCountriesClass(undefined, undefined);
  public restrictedCounries = RESTRICTED_COUNTRIES;
  public isRestrictedCountry = false;
  destroySubject$: Subject<void> = new Subject();

  constructor(public dataService: DataService,
              public profileState: ProfileState,
              public geoState: GeoState,
              public authService: AuthService,
              private router: Router) {
    // this.isLightTheme = this.dataService.getIsLightTheme();
    // this.dataService.getIsLightThemeEmitter()
  // .pipe(takeUntil(this.destroySubject$))
    //   .subscribe(data => {
    //     this.isLightTheme = data;
    //   });
  }

  ngOnInit(): void {
/*
    setTimeout(() => window.document.title = 'Biteeu', 500);
*/
    // setTimeout(() => this.dataService.findUserCountry(), 1000);

    if (this.authService.isLoggedIn) {
      this.profileState.loadUserProfileIfEmpty();
      fromMobx(() => this.profileState.storagedUserId)
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(
          (userId) => {
            this.userId = userId;
            this.dataService.joinRoomUserId('' + userId);
          }
        );
    }
  }

  ngOnDestroy() {
    this.dataService.leaveRoomUserId('' + this.userId);
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  public loadUserCountry() {
    const userCountry = this.geoState.userCountry;
    this.userCountry.ISO = userCountry && userCountry.ISO ? userCountry.ISO : undefined;
    this.userCountry.name = userCountry && userCountry.name ? userCountry.name : undefined;
    if (this.userCountry.ISO && this.userCountry.ISO.length) {
      this.verifyForRestrictedCountry(this.userCountry.ISO);
    }
    fromMobx(() => this.geoState.userCountry)
      .subscribe(_userCountry => {
        this.userCountry.ISO = _userCountry.ISO;
        this.userCountry.name = _userCountry.name;
        if (this.userCountry.ISO && this.userCountry.ISO.length) {
          this.verifyForRestrictedCountry(this.userCountry.ISO);
        }
      });
  }

  verifyForRestrictedCountry(iso: string) {
    this.isRestrictedCountry = this.restrictedCounries.some((country) => {
      return country.ISO === iso;
    });
    if (this.isRestrictedCountry) {
      this.authService.logout();
      this.router.navigate(['/markets']).then();
    }
  }

}
