import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {DataService} from '../../services/data.service';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {SvgService} from '../../services/svg.service';

@Component({
  selector: 'not-verified',
  templateUrl: './not-verified.component.html',
  styleUrls: ['./not-verified.component.scss']
})
export class NotVerifiedComponent implements OnInit {
  public isLightTheme = false;
  destroySubject$: Subject<void> = new Subject();

  constructor(private dialogRef: MatDialogRef<NotVerifiedComponent>,
              public svgService: SvgService,
              private dataService: DataService) {
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isLightTheme => {
        this.isLightTheme = isLightTheme;
      });
  }

  public ngOnInit() {
  }

  public close() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
    this.dialogRef.close();
  }
  
  
}
