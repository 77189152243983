import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {SvgService} from '../../../services/svg.service';
import {OrderHistoryInterface} from '../../../models/order-history.model';
import {SubscribeToUpdateTradesDataInterface} from '../../../models/subscribe-to-update-trades-data.model';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {AuthService} from '../../../services/auth.service';
import {ThemesModel} from '../../../models/themes.model';
import {OrderStopLimitClass} from '../../../models/order-stop-limit.class';
import {ORDER_STOP_LIMIT} from '../../../app.constants';
import {ProfileState} from '../../../store/profile.state';
import {fromMobx} from '../../../store/rx-from-mobx';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit, OnDestroy {
  public isLightTheme = false;
  public orders = null;
  public ordersInitial = Array<OrderHistoryInterface>(0);
  public _whatToDisplay = 'all';
  public _showOtherPairs = localStorage.getItem('show_other_pairs_history') || 'yes';
  public sortParams = {
    sortByPair: '',
    sortByType: '',
    sortByAmount: '',
    sortByPrice: '',
    sortByTriggerPrice: '',
    sortByTotal: '',
    sortByDate: 'down'
  };
  public isVisibleActualPair = false;
  destroySubject$: Subject<void> = new Subject();
  ThemesModel = ThemesModel;
  public errorMessage = '';
  public ordersStopLimit: Array<OrderStopLimitClass>;
  public refreshedStopLimitOrders = new Subject();
  public orderStopLimit = ORDER_STOP_LIMIT;
  public userId: number;
  public isInitiatedGetStopLimitOrders = false;

  round = Math.round;

  @Input() public isMinimized: boolean;
  @Input() public set whatToDisplay(filter: string) {
    this._whatToDisplay = filter || 'all';
  }
  public get whatToDisplay() {
    return this._whatToDisplay;
  }
  @Input() public set showOtherPairs(option: string) {
    this._showOtherPairs = option;
    localStorage.setItem('show_other_pairs_history', option);
    this.getOrders();
  }
  public get showOtherPairs() {
    return this._showOtherPairs;
  }
  @Output() public minimized = new EventEmitter<any>();

  constructor(public dataService: DataService,
              public profileState: ProfileState,
              public authService: AuthService,
              public svgService: SvgService) {
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }

  ngOnInit() {
    if (this.authService.isLoggedIn) {
      this.getOrders();
      this.refreshOrders();
      this.subsToUpdateOrders();
      this.getUserId();
      this.dataService.refreshedStopLimitOrders
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(() => {
          this.getStopLimitOrders(+this.userId, 'refreshedStopLimitOrders');
        });

    }
    this.dataService.getMarketPairNewEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        this.isVisibleActualPair = true;
        setTimeout(() => this.isVisibleActualPair = false, 2000);
        if (this.authService.isLoggedIn) {
          this.getOrders();
          // this.getStopLimitOrders(+this.userId, 'getMarketPairNewEmitter');
          // this.refreshOrders();
          // this.subsToUpdateOrders();
        }
      });
  }

  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  public refreshOrders() {
    this.dataService.refreshOrders
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        this.getOrders();
        this.getStopLimitOrders(+this.userId, 'refreshOrders');
      });
  }
  public subsToUpdateOrders() {
    this.dataService.SubscribeToUpdateOrders()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((data: SubscribeToUpdateTradesDataInterface) => {
        if (data.u) {
          this.getOrders();
          // console.log('SubscribeToUpdateOrders', data);
          this.getStopLimitOrders(+this.userId, 'SubscribeToUpdateOrders');
        }
      });
  }
  public getOrders() {
      this.dataService.getOrderHistory(this.showOtherPairs)
        .pipe(takeUntil(this.destroySubject$))
        .subscribe((res: Array<OrderHistoryInterface>) => {
          this.ordersInitial = res;
          this.orders = res;
          this.refreshedStopLimitOrders
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(() => {
              this.orders = this.addStopLimitOrdersToOrders(this.ordersInitial, this.ordersStopLimit, this.dataService.getPair());
            });
        });
  }
  public toggleSort(param) {
    if (this.sortParams[param] === 'up') {
      this.sortParams[param] = 'down';
    } else if (this.sortParams[param] === 'down') {
      this.sortParams[param] = '';
    } else {
      this.sortParams[param] = 'up';
    }
  }

  public addStopLimitOrdersToOrders(_orders: Array<any>, _ordersStopLimit: Array<OrderStopLimitClass>, pair: string): Array<any> {
    const ordersStopLimit = _ordersStopLimit.filter(order => {
      return ((order.isCancelled === 1 || order.executed === 1) && order.pair === pair);
    });
    const orders = [..._orders, ...ordersStopLimit];
    // console.log('history', orders);
    return orders;
  }

  public getStopLimitOrders(userId: number, from?: any) {
    // console.log(from || '?');
    this.errorMessage = '';
    this.dataService.getOpenStopLimitOrders(userId)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((res: Array<any>) => {
        const ordersStopLimit = res.map(v => {
          v.exchange = v.pair;
          v.is_canceled = v.isCancelled;
          v.date = v.updated_at || v.created_at;
          v.type = (<string>v.direction).toUpperCase() + ' (' + this.orderStopLimit + ')';
          return v;
        });
        this.ordersStopLimit = ordersStopLimit;
        this.refreshedStopLimitOrders.next();
        // this.orders = res.map(v => {
        //   v.date = v.placed;
        //   return v;
        // });
        // console.log('this.orders', this.orders);
      });
  }

  public getUserId() {
    this.profileState.loadUserProfileIfEmpty();
    fromMobx(() => this.profileState.storagedUserId)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((userId) => this.userId = userId);
  }

}
