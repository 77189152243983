import { Component, OnDestroy, OnInit } from '@angular/core';
import { LandingService } from '../../services/landing.service';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { MarketPair } from '../../interfaces/market-pair';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'bix-market',
  templateUrl: './market.component.html',
  styleUrls: ['./market.component.scss'],
})
export class MarketComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'MarketName',
    'Last',
    'percentChange',
    'BaseVolumeUSDT',
    'Link',
  ];
  dataSource = new MatTableDataSource<MarketPair>([]);

  sortData: Sort = { active: 'BaseVolumeUSDT', direction: 'asc' };

  private _destroySubject = new Subject<void>();

  isMobile = window.innerWidth <= 860;

  constructor(private _landingService: LandingService) {}

  ngOnInit(): void {
    this._landingService.marketsDetails$
      .pipe(takeUntil(this._destroySubject))
      .subscribe((data) => {
        this.dataSource.data = data;
        this.sort(this.sortData);
      });
  }

  ngOnDestroy(): void {
    this._destroySubject.next();
  }

  formattedMarketName(marketName: string): string {
    return marketName?.split('-')?.reverse()?.join('/');
  }

  marketNamePart(marketName: string, index: number): string {
    return marketName?.split('-')?.reverse()[index];
  }

  generateImgSrc(name: string): string {
    return `https://trade.intebix.kz/assets/img/coins/${name
      ?.split('-')[1]
      ?.toLowerCase()}.png`;
  }

  goToTrade(pair: MarketPair): void {
    location.href = `https://trade.intebix.kz/trades/${pair.MarketName}`;
  }

  sort(event: Sort): void {
    this.sortData = event;

    const sortFn = (a: MarketPair, b: MarketPair) => {
      const firstPair = event.direction === 'asc' ? a : b;
      const secondPair = event.direction === 'asc' ? b : a;

      switch (event.active) {
        case 'MarketName':
          const str1 = this.formattedMarketName(firstPair.MarketName);
          const str2 = this.formattedMarketName(secondPair.MarketName);
          return str1.localeCompare(str2);

        case 'Last':
          return (
            +(secondPair.LastPriceUSDT || secondPair.Last) -
            +(firstPair.LastPriceUSDT || firstPair.Last)
          );

        case 'percentChange':
          return +secondPair.percentChange - +firstPair.percentChange;

        case 'BaseVolumeUSDT':
          return +secondPair.BaseVolumeUSDT - +firstPair.BaseVolumeUSDT;

        default:
          return 0;
      }
    };

    this.dataSource.data = this.dataSource.data.sort(sortFn);
  }
}
