export interface BalanceInterface {
  balance: number;
  currency: string;
  id: string;
  pending: number;
  public_key: string;
  reserved: number;
}

export class BalanceModel implements BalanceInterface {
  balance = undefined;
  currency = '';
  id = '';
  pending = undefined;
  public_key = '';
  reserved = undefined;

  constructor(data?: BalanceInterface) {
    Object.assign(this, data);
  }
}
