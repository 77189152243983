import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {SvgService} from '../../../services/svg.service';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {SubscribeToUpdateTradesDataInterface} from '../../../models/subscribe-to-update-trades-data.model';
import {AuthService} from '../../../services/auth.service';
import {ThemesModel} from '../../../models/themes.model';
import {OrderStopLimitClass} from '../../../models/order-stop-limit.class';
import {ORDER_STATUS_COMPLETED, ORDER_STATUS_FAILED, ORDER_STATUS_OPEN, ORDER_STOP_LIMIT} from '../../../app.constants';
import {ProfileState} from '../../../store/profile.state';
import {fromMobx} from '../../../store/rx-from-mobx';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit, OnDestroy {
  @Input() public isMinimized: boolean;
  @Output() public minimized = new EventEmitter<any>();
  public isLightTheme = false;
  public ordersIsLoading = true;
  public orders;
  public ordersInitial;
  public errorMessage = '';
  public ordersSpinned = [];
  public ordersStopLimit: Array<OrderStopLimitClass>;
  // public refreshedStopLimitOrders = new Subject();
  public orderStopLimit = ORDER_STOP_LIMIT;
  public orderStatusOpen = ORDER_STATUS_OPEN;
  public orderStatusFailed = ORDER_STATUS_FAILED;
  public orderStatusCompleted = ORDER_STATUS_COMPLETED;
  public userId: number;
  public _showOtherPairs = localStorage.getItem('show_other_pairs_open') === 'true' || false;
  public sortParams = {
    sortByPair: '',
    sortByType: '',
    sortByAmount: '',
    sortByPrice: '',
    sortByTriggerPrice: '',
    sortByDate: 'down'
  };
  public isVisibleActualPair = false;
  public isInitiatedGetStopLimitOrders = false;
  public whatBlockToDisplay = 'orders';
  public whatToDisplay = 'all';
  @Input() public set showOtherPairs(option: boolean) {
    this._showOtherPairs = option;
    localStorage.setItem('show_other_pairs_open', JSON.stringify(option));
    this.getOrders();
  }
  public get showOtherPairs() {
    return this._showOtherPairs;
  }

  destroySubject$: Subject<void> = new Subject();
  ThemesModel = ThemesModel;

  constructor(public dataService: DataService,
              public authService: AuthService,
              public profileState: ProfileState,
              public svgService: SvgService) {
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }

  ngOnInit() {
    if (this.authService.isLoggedIn) {
      this.getOrders();
      this.refreshOrders();
      this.subsToUpdateOrders();
      this.getUserId();
    }
    this.dataService.getMarketPairNewEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        this.isVisibleActualPair = true;
        if (this.authService.isLoggedIn) {
          this.getOrders();
        }
        setTimeout(() => this.isVisibleActualPair = false, 2000);
      });
  }
  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  public refreshOrders() {
    this.dataService.refreshOrders
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        // console.log('refreshOrders');
        this.getOrders();
      });
  }
  public subsToUpdateOrders() {
    this.dataService.SubscribeToUpdateOrders()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((data: SubscribeToUpdateTradesDataInterface) => {
        console.log('SubscribeToUpdateOrders', data);
        if (data.u) {
          this.getOrders();
        }
        // console.log('SubscribeToUpdateOrders', data);
      });
  }
  public changeTab(tab: string) {
    this.whatBlockToDisplay = tab;
    if (tab === 'orders') {
      this.getOrders();
    }
  }
  public getOrders() {
    this.errorMessage = '';
    this.ordersIsLoading = true;
      this.dataService.getOpenOrders(this.showOtherPairs ? 'yes' : 'no')
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(res => {
          this.ordersIsLoading = false;
          const orders = res.map(v => {
            v.date = v.placed;
            v.side = (<string>v.side).toUpperCase();
            v['status'] = this.orderStatusOpen;
            return v;
          });
          this.ordersInitial = orders;
          this.orders = orders;
          if (this.userId) {
            this.getStopLimitOrders(+this.userId, 'from getOrders');
          }
          this.dataService.refreshedStopLimitOrders
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(() => {
              this.orders = this.addStopLimitOrdersToOrders(this.ordersInitial, this.ordersStopLimit, this.dataService.getPair());
            });
        });
  }

  public addStopLimitOrdersToOrders(_orders: Array<any>, _ordersStopLimit: Array<OrderStopLimitClass>, pair: string): Array<any> {
    // console.log('orders', _orders.length, 'ordersStopLimit', _ordersStopLimit.length);
    const ordersStopLimit = _ordersStopLimit.filter(order => {
      return order.isCancelled === 0 && order.executed === 0 && order.pair === pair;
    });
    const orders = [..._orders, ...ordersStopLimit];
    // console.log('active', orders);
    return orders;
  }

  public getStopLimitOrders(userId: number, from?: any) {
    // console.log(from || '?');
    this.isInitiatedGetStopLimitOrders = true;
    this.errorMessage = '';
      this.dataService.getOpenStopLimitOrders(userId)
        .pipe(takeUntil(this.destroySubject$))
        .subscribe((res: Array<any>) => {
          // console.log('StopLimitOrders from server', res);
          const ordersStopLimit = res.map(v => {
            v.exchange = v.pair;
            v.date = v.created_at;
            v.type = (<string>v.direction).toUpperCase() + ' (' + this.orderStopLimit + ')';
            return v;
          });
          this.ordersStopLimit = ordersStopLimit;
          this.dataService.refreshedStopLimitOrders.next();
          // this.orders = res.map(v => {
          //   v.date = v.placed;
          //   return v;
          // });
          // console.log('this.ordersStopLimit', this.ordersStopLimit);
        });
  }

  public getUserId() {
    this.profileState.loadUserProfileIfEmpty();
    fromMobx(() => this.profileState.storagedUserId)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((userId) => this.userId = userId);
  }


  public cancelOrder(orderId, type: string, order?) {
    if (type.toUpperCase().includes(this.orderStopLimit.toUpperCase())) {
      this.cancelStopLimitOrder(orderId);
    } else {
      this.cancelLimitOrder(orderId);
    }
  }

  public cancelLimitOrder(orderId) {
    this.dataService.cancelOpenOrder(orderId)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        this.getOrders();
        },
      err => {
        if (err.error.errorCode === '400_31') {this.errorMessage = err.error.message || err.message; }
        console.log('cancelOrder error', err);
      }
    );
  }
  public cancelStopLimitOrder(orderId) {
    this.dataService.cancelOpenStopLimitOrder(orderId)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(res => {
          // console.log(res);
          if (res && res.success && res.success === false) {
            this.errorMessage = res.error.message || res.message || '';
          }
        },
      err => {
        if (err.error.errorCode === '400_31') {this.errorMessage = err.error.message || err.message; }
        console.log('cancelOrder error', err);
      }
    );
  }

  public addSpinnedOrders(data: string) {
    this.ordersSpinned.push(data);
  }

  public isSpinnedOrder(data: string, orders: Array<string>): boolean {
    const store = {};
    for (let i = 0; i < orders.length; i++) {
      const key = orders[i]; // для каждого элемента создаём свойство
      store[key] = true; // значение здесь не важно
    }
    return !!store[data];
  }
  public toggleSort(param) {
    if (this.sortParams[param] === 'up') {
      this.sortParams[param] = 'down';
    } else if (this.sortParams[param] === 'down') {
      this.sortParams[param] = '';
    } else {
      this.sortParams[param] = 'up';
    }
  }
}
