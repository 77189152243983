import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, LOCALE_ID, OnDestroy} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'bix-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboutUsComponent implements OnDestroy {

  private _destroyer = new Subject<void>();

  constructor() {
  }

  ngOnDestroy(): void {
    this._destroyer.next();
    this._destroyer.complete();
  }

}
