import {Component, OnDestroy, OnInit} from '@angular/core';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-fees-and-limits',
  templateUrl: './fees-and-limits.component.html',
  styleUrls: ['./fees-and-limits.component.scss']
})

export class FeesAndLimitsComponent implements OnDestroy, OnInit {

  scrollConfig: PerfectScrollbarConfigInterface = {
    suppressScrollX: false
  };

  feesAndLimits = [
    { name: 'Cardano', code: 'ADA', min: 20, fee: 2 },
    { name: 'Bitcoin Cash', code: 'BCH', min: 0.004, fee: 0.04869 },
    { name: 'Bitcoin', code: 'BTC', min: 0.0001, fee: 0.001 },
    { name: 'Ethereum', code: 'ETH', min: 0.001, fee: 0.0072 },
    { name: 'Tether', code: 'USDT', min: 1, fee: 25.6130 },
    { name: 'Ripple', code: 'XRP', min: 0.5, fee: 0.5 }
  ];

  pairsByCurrency = {
    btc: [
      {name: 'Cardano', code: 'ADA', min: 7.16179817, fee: 0.3},
      {name: 'Bitcoin Cash', code: 'BCH', min: 0.02719467, fee: 0.3},
      {name: 'Ripple', code: 'XRP', min: 7.06395077, fee: 0.3}
    ],
    usdt: [
      {name: 'Cardano', code: 'ADA', min: 6.87983088, fee: 0.3},
      {name: 'Bitcoin Cash', code: 'BCH', min: 0.02608763, fee: 0.3},
      {name: 'Bitcoin', code: 'BTC', min: 0.00015371, fee: 0.3},
      {name: 'Ethereum', code: 'ETH', min: 0.00224382, fee: 0.3},
      {name: 'Ripple', code: 'XRP', min: 6.78527370, fee: 0.3}
    ],
    eth: [
      {name: 'Cardano', code: 'ADA', min: 7.21203621, fee: 0.3},
      {name: 'Bitcoin Cash', code: 'BCH', min: 0.02705643, fee: 0.3},
      {name: 'Ripple', code: 'XRP', min: 7.01594509, fee: 0.3}
    ]
  };

  mainTab: 'FEES_AND_LIMITS' | 'TRADING_FEE' = 'FEES_AND_LIMITS';
  selectedCurrency = 'BTC';

  constructor(private _dataService: DataService) {
  }

  ngOnInit(): void {
    this._dataService.getFeesAndLimits().toPromise().then(
      (v) => {
        const feeMap = new Map(v.res?.map((cur) => [cur.symbol, cur.totalFee]))
        this.feesAndLimits = this.feesAndLimits.map((v) => ({...v, fee: +feeMap.get(v.code) || v.fee}))
      }
    )
  }

  ngOnDestroy(): void {
  }

  generateImgSrc(code: string): string {
    return `assets/img/coins/${code.toLowerCase()}.png`;
  }
}
