import { Pipe, PipeTransform } from '@angular/core';
import {DataService} from '../services/data.service';
import {SvgService} from '../services/svg.service';

@Pipe({
  name: 'convertExpToNumber'
})
export class ConvertExpToNumberPipe implements PipeTransform {

  constructor(public dataService: DataService) {}

  transform(_value: number): string {
    const value = '' + _value;
    const newValue = ((value.indexOf('e') >= 0) || (value.indexOf('E') >= 0)) ? this.dataService.convertExpToString(_value) : value;

    const newValueArr = newValue.split('.');
    let decimal = newValueArr[1] ? newValueArr[1] : '00000000';

    if (newValueArr && newValueArr[1] && newValueArr[1].length) {
      switch (newValueArr[1].length) {
        case 1: decimal = newValueArr[1] + '0000000'; break;
        case 2: decimal = newValueArr[1] + '000000'; break;
        case 3: decimal = newValueArr[1] + '00000'; break;
        case 4: decimal = newValueArr[1] + '0000'; break;
        case 5: decimal = newValueArr[1] + '000'; break;
        case 6: decimal = newValueArr[1] + '00'; break;
        case 7: decimal = newValueArr[1] + '0'; break;
      }
    } else {
      // console.log(_value);
    }

    return newValueArr[0] + '.' + decimal;
  }

}
