import { Component } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {KycModalComponent} from '../../dialogs/kyc-modal/kyc-modal.component';

@Component({
  selector: 'app-kyc-page',
  templateUrl: './kyc-page.component.html',
  styleUrls: ['./kyc-page.component.scss']
})
export class KycPageComponent {
  constructor(
    private dialog: MatDialog,
    public router: Router,
    private route: ActivatedRoute
  ) {
    const token = this.route.snapshot.paramMap.get('token');
    const url = this.router.url.indexOf('/') === this.router.url.lastIndexOf('/') ? this.router.url : this.router.url
      .slice(0, this.router.url.lastIndexOf('/'));
    this.dialog.open(KycModalComponent, {
      data: {'token': token, 'action': url.split('/')[2]}
    });
  }

}
