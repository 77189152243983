import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AlertDialogData} from '../../interfaces/alert-dialog-data';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent<T> implements OnInit {

  constructor(public dialogRef: MatDialogRef<AlertDialogComponent<T>>,
              @Inject(MAT_DIALOG_DATA) public data: AlertDialogData<T>) { }

  ngOnInit(): void {
    if (!this.data.close) {
      this.data.close = 'CLOSE';
    }

    if (!this.data.closeColor) {
      this.data.closeColor = null;
    }
  }

  close(result: boolean): void {
    this.dialogRef.close(result);
  }

}
