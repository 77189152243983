import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lastSort'
})
export class LastSortPipe implements PipeTransform {

  transform(value: any, direction: any): any {
    // let returnVal = [];
    if (value) {
      /*for (let i = 0, j = 0; i < value.length; i++) {
        for (const key in value[i]) {
          if (key !== 'name') {
            returnVal[j] = {
              name: value[i].name
            };
            returnVal[j][key] = value[i][key];
            j++;
          }
        }
      }*/
      if (direction) {
        return value.sort((a, b) => {
          const keyA = Object.keys(a).filter(v =>  v !== 'name')[0];
          const keyB = Object.keys(b).filter(v =>  v !== 'name')[0];
          if (direction === 'up')
            return a[keyA].Last - b[keyB].Last;
          else
            return b[keyB].Last - a[keyA].Last;
        });
      }
      return value;
    }
  }
}
