import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dateSort'
})
export class DateSortPipe implements PipeTransform {

  transform(value: any, direction: any): any {
    if (value) {
      if (direction)
        return value.sort((a, b) => {
          if (direction === 'up')
            return +new Date(a.date) - +new Date(b.date);
          else
            return +new Date(b.date) - +new Date(a.date);
        });
      return value;
    }
  }

}
