import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {DataService} from '../../services/data.service';
import {takeUntil, filter} from 'rxjs/operators';
import {PublicApi} from '../../models/public-api.model';
import {apiMethods} from './mock';
@Component({
  selector: 'app-public-api',
  templateUrl: './public-api.component.html',
  styleUrls: ['./public-api.component.scss']
})

export class PublicApiComponent implements OnDestroy, OnInit {
  public isLightTheme = false;
  public isPending = true;
  public toggle = false;
  public sortParams = {
    sortByCurrency: ''
  };
  destroySubject$: Subject<void> = new Subject();

  public apiMethods: PublicApi[] = apiMethods;
  constructor(
    public dataService: DataService) {
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }
  public toggleSort(param) {
    if (this.sortParams[param] === 'up') {
      this.sortParams[param] = 'down';
    } else if (this.sortParams[param] === 'down') {
      this.sortParams[param] = '';
    } else {
      this.sortParams[param] = 'up';
    }
  }

  ngOnDestroy() {

  }
  ngOnInit() {

  }
}
