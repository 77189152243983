import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bix-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  HtmlAccountDeactivated = 'accountDeactivatedA';
  constructor() {}

  ngOnInit(): void {}
}
