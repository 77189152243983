import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'tokenNameSort'
})
export class TokenNameSortPipe implements PipeTransform {

  transform(value: any, direction: any): any {
    if (value)
      if (direction === 'up')
        return value.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
      else if (direction === 'down')
        return value.sort((a, b) => {
          if (a.name < b.name) return 1;
          if (a.name > b.name) return -1;
          return 0;
        });
      else return value;
  }

}
