import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router, RoutesRecognized} from '@angular/router';
import {filter, map, tap} from 'rxjs/operators';

@Component({
  selector: 'app-main-wrapper',
  templateUrl: './main-wrapper.component.html',
  styleUrls: ['./main-wrapper.component.scss']
})
export class MainWrapperComponent implements OnInit {
  isLightTheme = false;

  isMobile = window.innerWidth < 860;

  isMarketPage = true;

  isMenuOpened = false;

  constructor(public dataService: DataService, private router: Router) { }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.isMobile = window.innerWidth < 860;
  }

  ngOnInit(): void {

    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .subscribe(data => {
        this.isLightTheme = data;
      });

  }

  navigate(url: string) {
    this.router.navigate([url]);
    this.isMenuOpened = false;
  }
}
