import { Component, OnDestroy, OnInit } from '@angular/core';
import { SvgService } from '../../services/svg.service';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { MyProfileInterface } from '../../models/my-profile.model';
import { ThemesModel } from '../../models/themes.model';
import { Subscription } from 'rxjs/internal/Subscription';
import {ActivatedRoute} from '@angular/router';
import {GeoState} from '../../store/geo.state';
import {fromMobx} from '../../store/rx-from-mobx';
import {ProfileState} from '../../store/profile.state';

@Component({
  selector: 'app-markets',
  templateUrl: './markets.component.html',
  styleUrls: ['./markets.component.scss'],
})
export class MarketsComponent implements OnInit, OnDestroy {
  public hiddenBlocks = {
    chart: false,
    tickers: false,
    balances: false,
    orders: false,
    calculator: false,
    orderHistory: false,
    orderBook: false,
    marketDepth: false,
    trades: false,
    orderMarkets: false,
  };
  public isGeoBlockedCountry = false;
  public isGeoBlockedCity = false;
  public ThemesModel = ThemesModel;
  destroySubject$: Subject<void> = new Subject();
  private currentThemeSubscription: Subscription;
  public isBcxg: boolean;

  constructor(
    public svgService: SvgService,
    public authService: AuthService,
    public profileState: ProfileState,
    public dataService: DataService,
    private geoState: GeoState,
    public route: ActivatedRoute,
  ) {
    route.url.subscribe(url => {
      this.dataService.market = url[url?.length - 1]?.path || 'BTC-ETH';
    });
  }

  ngOnInit() {
    // setTimeout(() => this.dataService.findUserCountry(), 1000);
    this.dataService.joinRoom();

    const pair = this.dataService.getPair();
    // this.isBcxg =
      // (pair.includes('USDT') && pair.includes('BCXG')) ||
      // (pair.includes('USDT') && pair.includes('DOT')) ||
      // (pair.includes('USDT') && pair.includes('XRP')) ||
      // (pair.includes('BTC') && pair.includes('XRP')) ||
      // (pair.includes('BTC') && pair.includes('BCXG')) ||
      // (pair.includes('BTC') && pair.includes('LTC')) ||
      // (pair.includes('ETH') && pair.includes('LTC'))

    if (this.authService.isLoggedIn) {
      this.profileState.loadUserProfileIfEmpty();
      fromMobx(() => this.profileState.storagedUserId).pipe(takeUntil(this.destroySubject$)).subscribe(
        (userId) => this.dataService.joinRoomUserId('' + userId)
      );
      this.checkGeoForBlackListedCountry();
      this.checkIfResidenceCountryIsInBlackListAndIfCitizenshipIsInBlackList();
    } else {
        this.profileState.checkUserIsCookiesAgreed();
    }
    this.dataService.getMarketMinOrderAmount();
    this.currentThemeSubscription = this.dataService.currentThemeSubject.subscribe(res => {
      this.dataService.setCurrentTheme(res);
    });
  }

  ngOnDestroy() {
    this.dataService.leaveRoom();
    this.dataService.leaveRoomUserId('' + this.profileState.storagedUserId);
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
    this.dataService.passExchangeAmount(undefined);
    this.dataService.setExchangeAmount(undefined);
    this.dataService.passExchangePrice(undefined);
    this.dataService.setExchangePrice(undefined);
    this.currentThemeSubscription.unsubscribe();
  }
  public hideToggle(block) {
    this.hiddenBlocks[block] = !this.hiddenBlocks[block];
  }

  public blockUserActivity() {
    // this.router.navigate(['/login']);
    // this.dataService.setLoginIncomeErrorStatus(['isGeoBlockedCountry']);
    // this.dataService.passLoginIncomeErrorStatus(['isGeoBlockedCountry']);
    // this.authService.logout();
  }

  public checkIfResidenceCountryIsInBlackListAndIfCitizenshipIsInBlackList() {
    if (this.geoState.isResidenceCountryInBlackList || this.geoState.isCitizenshipInBlackList) {
      this.blockUserActivity();
    }
    fromMobx(() => this.geoState.isResidenceCountryInBlackList)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isResidenceCountryInBlackList => {
        if (isResidenceCountryInBlackList) {
          this.blockUserActivity();
        }
      });
    fromMobx(() => this.geoState.isCitizenshipInBlackList)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isCitizenshipInBlackList => {
        if (isCitizenshipInBlackList) {
          this.blockUserActivity();
        }
      });
  }

  public checkGeoForBlackListedCountry() {
    this.isGeoBlockedCountry = this.geoState.isGeoCountryIsInBlackList;
    this.isGeoBlockedCity = this.geoState.isGeoCityIsInBlackList;
    if (this.geoState.isGeoCountryIsInBlackList || this.geoState.isGeoCityIsInBlackList) {
      this.blockUserActivity();
    }
    fromMobx(() => this.geoState.isGeoCountryIsInBlackList).pipe(takeUntil(this.destroySubject$))
      .subscribe(
        (isGeoBlockedCountry) => {
          this.isGeoBlockedCountry = isGeoBlockedCountry;
          this.blockUserActivity();
        }
      );
    fromMobx(() => this.geoState.isGeoCityIsInBlackList).pipe(takeUntil(this.destroySubject$))
      .subscribe(
        (isGeoBlockedCity) => {
          this.isGeoBlockedCity = isGeoBlockedCity;
          this.blockUserActivity();
        }
      );
  }

  // private getMarketMinOrderAmount() {
  //   this.dataService.getMarketPairsShortInfoServer()
  //     .pipe(takeUntil(this.destroySubject$))
  //     .subscribe(data => {
  //       const marketPairMinOrderAmount = data[this.dataService.getPair()];
  //       this.dataService.passMarketPairMinOrderAmount(marketPairMinOrderAmount);
  //       this.dataService.setMarketPairMinOrderAmount(marketPairMinOrderAmount);
  //     });
  // }

}
