import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceSort'
})
export class PriceSortPipe implements PipeTransform {

  transform(value: any, direction: any): any {
    if (direction)
      return value.sort((a, b) => {
        if (direction === 'up')
          return a.price - b.price;
        else
          return b.price - a.price;
      });
    return value;
  }

}
