import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[bixCarouselItem]'
})
export class CarouselItemDirective {

  constructor(public templateRef: TemplateRef<any>) {}

}
