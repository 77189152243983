export class CurrencyLastPriceClass {
  pair: string;
  currencyMain: string;
  currencyAdditional: string;
  lastPrice: number;

  constructor(pair: string,
              currencyMain: string,
              currencyAdditional: string,
              lastPrice: number
  ) {
    this.pair = pair;
    this.currencyMain = currencyMain;
    this.currencyAdditional = currencyAdditional;
    this.lastPrice = lastPrice;
  }
}
