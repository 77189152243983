import {Directive, forwardRef, HostBinding, Input, OnChanges, StaticProvider} from '@angular/core';
import {NG_VALIDATORS, ValidatorFn} from '@angular/forms';
import {AbstractValidatorDirective, minValidator, toFloat} from './abstract-validator.directive';

export const BIX_MIN_VALIDATOR: StaticProvider = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => MinDirective),
  multi: true
};

@Directive({
  selector: 'input[appMin][formControlName],input[appMin][formControl],input[appMin][ngModel]',
  providers: [BIX_MIN_VALIDATOR]
})
export class MinDirective extends AbstractValidatorDirective implements OnChanges {
  @HostBinding('attr.appMin')
  @Input() appMin!: string|number|null;
  inputName = 'appMin';
  normalizeInput = (input: string|number): number => toFloat(input);
  createValidator = (min: number): ValidatorFn => minValidator(min);

}
