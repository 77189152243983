import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

@Injectable()
export class AppConfig {

  static init(translateService: TranslateService): () => Promise<any> {
    return ()  => {
      return Promise.resolve(true).then(() => {
        translateService.addLangs(['en', 'ru', 'zh']);
        const currentLang = window.localStorage.getItem('preferred_lang');
        if (!currentLang){
          window.localStorage.setItem('preferred_lang', 'en');
          translateService.use('en');
        }else {
          translateService.use(currentLang);
        }
      });
    };
  }
  static httpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
  }
}
