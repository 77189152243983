import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  Input,
  OnDestroy
} from "@angular/core";
import { CarouselItemDirective } from "../../directive/carousel-item.directive";
import {
  animate,
  AnimationBuilder,
  AnimationFactory,
  AnimationPlayer,
  style,
} from "@angular/animations";

@Component({
  selector: "bix-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
})
export class CarouselComponent implements AfterViewInit {
  @ContentChildren(CarouselItemDirective)
  items: QueryList<CarouselItemDirective>;
  @ViewChild("carousel") private _carousel: ElementRef;

  private _player: AnimationPlayer;
  private _currentSlide = 0;
  private _slideWidth: number;
  private _slideIntervalId: any;

  @Input() specificClass: string = "";
  @Input() autoSlideDuration?: number;

  constructor(private _builder: AnimationBuilder) {}

  @HostListener("window:resize", ["$event"])
  onResize(event): void {
    this._slideWidth =
      this._carousel.nativeElement.getBoundingClientRect().width;
    const offset = this._currentSlide * this._slideWidth;
    this._animate(offset);
  }

  ngAfterViewInit(): void {
    this._slideWidth =
      this._carousel.nativeElement.getBoundingClientRect().width;

    if (this.autoSlideDuration) {
      this._slideIntervalId = setInterval(() => {
        this.next();
      }, this.autoSlideDuration);
    }
  }

  ngOnDestroy(): void {
    if (this._slideIntervalId) {
      clearInterval(this._slideIntervalId);
    }
  }

  next(): void {
    this._currentSlide = (this._currentSlide + 1) % this.items.length;
    const offset = this._currentSlide * this._slideWidth;
    this._animate(offset);
  }

  prev(): void {
    this._currentSlide =
      (this._currentSlide - 1 + this.items.length) % this.items.length;
    const offset = this._currentSlide * this._slideWidth;
    this._animate(offset);
  }

  private _animate(offset: number): void {
    const myAnimation: AnimationFactory = this._builder.build([
      animate(
        "0.5s ease-out",
        style({ transform: `translateX(-${offset}px)` })
      ),
    ]);

    this._player = myAnimation.create(this._carousel.nativeElement);
    this._player.play();
  }
}
