import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pairSort'
})
export class PairSortPipe implements PipeTransform {

  transform(value: any, direction: any): any {
    if (direction)
      return value.sort((a, b) => {
        if (direction === 'up') {
          if (a.exchange < b.exchange) return -1;
          if (a.exchange > b.exchange) return 1;
          return 0;
        } else if (direction === 'down') {
          if (a.exchange < b.exchange) return 1;
          if (a.exchange > b.exchange) return -1;
          return 0;
        }
      });
    return value;
  }

}
