import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: 'input[bixRegExp], textarea[bixRegExp]'
})
export class RegExpDirective {

  @Input() bixRegExp: RegExp;

  private _specialKeys = ['Backspace', 'Tab', 'End', 'Home', 'Left', 'Right', 'ArrowLeft', 'ArrowRight'];

  constructor(private _elementRef: ElementRef) { }

  @HostListener('keydown', [ '$event' ])
  onKeyDown(event: KeyboardEvent): void {
    if (this._specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    const key = event.which || event.keyCode; // keyCode detection
    const ctrl = event.ctrlKey || event.metaKey; // ctrl detection

    if (ctrl && (key === 86 || key === 67)) {
      return; // pass
    }

    this._tryInsert(event.key, event);
  }

  @HostListener('paste', [ '$event' ])
  onPaste(event: ClipboardEvent): void {
    const text = event.clipboardData.getData('Text');

    if (text) {
      this._tryInsert(text, event);
    }
  }

  private _tryInsert(insert: string, event: Event): void {
    const current = this._elementRef.nativeElement.value;
    const next = [
      current.slice(0, this._elementRef.nativeElement.selectionStart),
      insert,
      current.slice(this._elementRef.nativeElement.selectionEnd)
    ].join('');
    if (next && !!this.bixRegExp && !String(next).match(this.bixRegExp)) {
      //console.log('next', next);
      event.preventDefault();
    }
  }
}
