import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';

@Injectable({
  providedIn: 'root'
})

export class AdvcashService {
  httpOptions;
  httIPOptions;

  constructor(public base: BaseService,
              private http: HttpClient
  ) {
  }

  public createAdvcashInvoice(url: string, data?: any): Observable<any> {
    return this.base.post(url, data);
  }

  public sendInvoiceId(url: string, data?: any): Observable<any> {
    return this.http.post(url, data);
  }

  public getCurrencyExchangeRates(url: string, data?: any): Observable<any> {
    return this.base.post(url, data);
  }

  public createPostPromise(url: string, data?: any): Promise<any> {
    return this.base.post(url, data).toPromise();
  }
}
